import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import DebounceInput from "../common/DebounceInput";
import { get, isEmpty } from "lodash";
import SaveTemplateModal from "./SaveTemplateModal";
import useGetReport from "./services/useGetReport";
import useDeleteTemplate from "./services/useDeleteTemplate";
import Toast from "../Toast";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import { useTranslation } from "react-i18next";
import constants from "../../utils/constants";
import SignatureModal from "./SignatureModal";

function ReportTemplate({ isOpen, onCloseReportModal }) {
  const { t } = useTranslation();
  const { isDarkMode } = useStoreState(Store, (s) => ({
    isDarkMode: s.isDarkMode,
  }));

  const [search, setSearch] = useState("");
  const [isTemplateSaveModal, setIsTemplateSaveModal] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isSignatureModal, setIsSignatureModal] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [pinnedReportData, setPinnedReportData] = useState({});
  const [showDeleteIconId, setShowDeleteIconId] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const deleteGroup = useDeleteTemplate();

  const {
    data: reportTemplateData,
    isLoading,
    refetch: refetchReports,
  } = useGetReport(isOpen);

  const templateData = get(reportTemplateData, ["all_templates"]);

  useEffect(() => {
    if (search) {
      setReportData(
        templateData?.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      const pinnedData = templateData?.filter((data) => data?.is_pinned);
      const normalData = templateData?.filter((data) => !data?.is_pinned);
      setReportData(normalData);
      if (pinnedData?.length > 0) {
        setPinnedReportData(pinnedData[0]);
      } else {
        setPinnedReportData({});
      }
    }
  }, [search, templateData]);

  const onCloseSaveModal = () => {
    setSelectedTemplate({});
    setIsPinned(false);
    setIsTemplateSaveModal(false);
  };

  const onCloseSignatureModal = () => {
    setIsSignatureModal(false);
  };

  const onDelete = async (id) => {
    try {
      await deleteGroup.mutateAsync(id);
      refetchReports();
      Toast.success(t("report_templates.toast.delete_success"));
    } catch (err) {
      Toast.error(t("report_templates.toast.delete_failed"));
    }
  };

  return (
    <>
      <div className="flex flex-col h-full mx-4 pt-4">
        <div className="p-5 rounded-lg border w-full border-gray-700 my-5 flex justify-between">
          <div className="text-left space-y-2">
            <p className="font-medium">{t("report_signature.title")}</p>
            <p className="text-md text-gray-200">{t("report_signature.subtitle")}</p>
          </div>
          <Button
            className="px-6 text-base py-2 bg-teal-800"
            onClick={() => {
              setIsSignatureModal(true);
            }}
            isPrimary={isDarkMode}
          >
            {t("button.edit_preview")}
          </Button>
        </div>
        <p className="pb-5 text-left">{t("report_templates.title")}</p>
        <div className="flex items-center justify-between">
          <div className="w-1/2">
            <DebounceInput
              value={search}
              inputClassName="border-none"
              onChange={setSearch}
              placeholder={t("report_templates.search_placeholder")}
              className="rounded-md text-gray-800 dark:text-white border !py-2 w-full border-gray-700"
            />
          </div>
          <Button
            isSecondary={isDarkMode}
            className="grow-1 flex space-x-2 px-4 text-base py-2 ml-4"
            onClick={() => {
              setIsTemplateSaveModal(true);
            }}
            isPrimary={!isDarkMode}
          >
            <PlusIcon className="h-6 w-6" aria-hidden="true" />
            <p>{t("report_templates.new_template")}</p>
          </Button>
        </div>
        <div className="rounded-md my-4 text-left py-3 bg-gray-300 dark:bg-gray-900 max-h-80 overflow-y-auto scrollbar-light dark:scrollbar">
          <div className="border-b px-4 uppercase py-3 text-[14px] text-gray-800 dark:text-gray-500 border-gray-800 grid grid-cols-12">
            <p className="col-span-1">#</p>
            <p className="col-span-2">{t("modality_key")}</p>
            <p className="col-span-4">{t("template_name_key")}</p>
            <p className="col-span-5">{t("edited_by_key")}</p>
          </div>
          {isLoading ? (
            <div className="flex flex-col h-full w-full items-center justify-center overflow-hidden">
              <div
                className="border-solid border-white border-2 border-r-transparent animate-spin inline-block w-8 h-8 rounded-full text-blue-600 mr-3"
                role="status"
              />
            </div>
          ) : (
            <>
              <div className="border border-teal-700 rounded-lg overflow-hidden m-3">
                <p className="text-teal-300 bg-teal-900 p-3 text-sm">
                  {t("ai_assistant_negative_chest_xrays")}
                </p>
                {isEmpty(pinnedReportData) ? (
                  <div
                    onClick={(e) => {
                      setIsTemplateSaveModal(true);
                      setSelectedTemplate({
                        modality: "CR/DR/DX",
                        name: constants.DEFAULT_FILTER_NAME.negativeCXR,
                      });
                      setIsPinned(true);
                      e.stopPropagation();
                    }}
                    className="border-b p-4 relative cursor-pointer dark:hover:bg-gradient-to-r from-teal-900 text-[14px] text-gray-800 dark:text-gray-600 border-gray-600 dark:border-gray-800 grid grid-cols-12"
                  >
                    <p className="col-span-1">--</p>
                    <p className="col-span-2">--</p>
                    <p className="col-span-4 overflow-hidden mr-6">
                      {t("default_qxr_report")}
                    </p>
                    <div className="col-span-5 space-x-2 overflow-hidden mr-6 grid grid-cols-12 items-center">
                      <UserIcon className="h-4 w-4 col-span-1 text-gray-800 dark:text-white" />
                      <p>--</p>
                    </div>
                    <PencilSquareIcon
                      className="absolute z-10 right-2 text-gray-800 cursor-pointer hover:text-gray-800 dark:hover:text-teal-100 -top-2 translate-y-[100%] h-5 w-5 dark:text-white"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      setSelectedTemplate(pinnedReportData);
                      setIsTemplateSaveModal(true);
                      setIsPinned(true);
                      e.stopPropagation();
                    }}
                    className="border-b p-4 relative cursor-pointer dark:hover:bg-gradient-to-r from-teal-900 text-[14px] text-gray-800 dark:text-white border-gray-600 dark:border-gray-800 grid grid-cols-12"
                  >
                    <p className="col-span-1">1</p>
                    <p className="col-span-2">{pinnedReportData?.modality}</p>
                    <p className="col-span-4 overflow-hidden mr-6">
                      {pinnedReportData?.name}
                    </p>
                    <div
                      title={
                        pinnedReportData?.edited_by?.email ??
                        pinnedReportData?.created_by?.email
                      }
                      className="col-span-5 space-x-2 overflow-hidden mr-6 grid grid-cols-12 items-center"
                    >
                      <UserIcon className="h-4 w-4 col-span-1 text-gray-800 dark:text-white" />
                      <p>
                        {" "}
                        {pinnedReportData?.edited_by?.email ??
                          pinnedReportData?.created_by?.email}
                      </p>
                    </div>
                    <PencilSquareIcon
                      className="absolute z-10 right-2 text-gray-800 hover:text-gray-800 cursor-pointer dark:hover:text-teal-100 -top-2 translate-y-[100%] h-5 w-5 dark:text-white"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {reportData?.map((item, index) => (
                <div
                  key={index}
                  onMouseEnter={(e) => {
                    setShowDeleteIconId(item.id);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setShowDeleteIconId();
                    e.stopPropagation();
                  }}
                  onClick={() => {
                    setSelectedTemplate(item);
                    setIsTemplateSaveModal(true);
                  }}
                  className="border-b px-4 relative cursor-pointer py-3 hover:bg-gray-400 dark:hover:bg-teal-800 text-[14px] text-gray-800 dark:text-white border-gray-600 dark:border-gray-800 grid grid-cols-12"
                >
                  <p className="col-span-1">
                    {isEmpty(pinnedReportData) ? index + 1 : index + 2}
                  </p>
                  <p className="col-span-2">{item.modality}</p>
                  <p className="col-span-4 overflow-hidden mr-6">{item.name}</p>
                  <div
                    title={item?.edited_by?.email ?? item?.created_by?.email}
                    className="col-span-5 space-x-2 overflow-hidden mr-6 grid grid-cols-12 items-center"
                  >
                    <UserIcon className="h-4 w-4 col-span-1 text-gray-800 dark:text-white" />
                    <p> {item?.edited_by?.email ?? item?.created_by?.email}</p>
                  </div>
                  {showDeleteIconId === item.id && (
                    <TrashIcon
                      onClick={(e) => {
                        onDelete(item.id);
                        e.stopPropagation();
                      }}
                      className="absolute z-10 right-4 text-gray-800 hover:text-gray-800 dark:hover:text-teal-100 -top-2 translate-y-[100%] h-5 w-5 dark:text-white"
                      aria-hidden="true"
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <SignatureModal
        isVisible={isSignatureModal}
        onClose={onCloseSignatureModal}
        selectedTemplate={selectedTemplate}
        onCloseReportModal={onCloseReportModal}
      />

      <SaveTemplateModal
        isVisible={isTemplateSaveModal}
        onClose={onCloseSaveModal}
        selectedTemplate={selectedTemplate}
        isPinned={isPinned}
        refetchReports={refetchReports}
      />
    </>
  );
}

export default ReportTemplate;
