import { TrashIcon, ChevronDownIcon, CheckIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, Fragment } from "react";
import Button from "../common/Button";
import ReportEditor from "../Report/ReportEditor";
import Modal from "../common/Modal";
import PreviewTemplateModal from "./PreviewTemplateModal";
import Store from "../../utils/Store";
import useDeleteTemplate from "./services/useDeleteTemplate";
import Toast from "../Toast";
import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  customRegexValidation,
  startCharCheckValidation,
  validateFields,
} from "../../utils/regexValidation";
import { useTranslation } from "react-i18next";

const modalityList = [
  { id: 1, name: "CR/DR/DX", value: "CR/DR/DX" },
  { id: 2, name: "CT", value: "CT" },
  { id: 3, name: "MRI", value: "MRI" },
  { id: 4, name: "CTA", value: "CTA" },
  { id: 5, name: "CTP", value: "CTP" },
  { id: 6, name: "NCCT", value: "NCCT" },
  { id: 7, name: "Other", value: "Other" },
];

function SaveTemplateModal({
  selectedTemplate,
  isVisible,
  onClose,
  isPinned,
  refetchReports,
}) {
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState();
  const [isTemplatePreviewModal, setIsTemplatePreviewModal] = useState(false);
  const [templateModality, setTemplateModality] = useState(modalityList[0]);
  const [currentReport, setCurrentReport] = useState("");
  const [error, setError] = useState("");
  const impression = selectedTemplate.template ?? "";
  const [query, setQuery] = useState("");
  const deleteGroup = useDeleteTemplate();

  const onCloseModal = () => {
    onClose();
    setError("");
  };

  const onClosePreviewModal = (key) => {
    setIsTemplatePreviewModal(false);
    if (key) {
      onCloseModal();
    }
  };

  const filteredModality =
    query === ""
      ? modalityList
      : modalityList.filter((modality) =>
          modality.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const onDelete = async () => {
    try {
      await deleteGroup.mutateAsync(selectedTemplate?.id);
      onCloseModal();
      Toast.success(t("report_templates.toast.delete_success"));
    } catch (err) {
      onCloseModal();
      Store.update((s) => {
        s.isReportTemplateOpen = false;
      });
      Toast.error(t("report_templates.toast.delete_failed"));
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateModality(selectedTemplate.modality);
      setTemplateName(selectedTemplate.name);
    }
  }, [selectedTemplate]);

  const handlePreviewModal = () => {
    const validationResults = validateFields([
      {
        fn: customRegexValidation,
        value: templateName,
        err: ` ${t("report_templates.validation.special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: templateName,
        err: ` ${t("report_templates.validation.start")},`,
      },
    ]);
    if (validationResults) {
      setError(validationResults);
    } else {
      setIsTemplatePreviewModal(true);
    }
  };

  return (
    <>
      <Modal
        show={isVisible}
        onClose={() => {
          onClose();
        }}
        title={t("setting_report")}
        className="w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12"
        // closeOnClickAway
      >
        <div
          className="flex flex-col h-full mt-4 pt-6"
          onClick={(e) => e.preventDefault()}
        >
          <div className="px-4">
            <div className="flex space-x-8 justify-between items-center">
              <div className="flex w-2/3 space-x-6 items-center">
                <label id="name" className="text-[14px]">
                  {t("name_key")}
                </label>
                <input
                  className="rounded-lg w-full border-none text-gray-800 bg-gray-100 dark:bg-white disabled:bg-gray-300 disabled:dark:bg-gray-300"
                  type={"text"}
                  defaultValue={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  disabled={isPinned}
                  id="name"
                />
              </div>
              <div className="flex space-x-6 items-center">
                <label id="modality" className="text-[14px]">
                  {t("modality_key")}
                </label>
                <div className="relative z-10">
                  <Combobox
                    value={templateModality}
                    onChange={setTemplateModality}
                    disabled={isPinned}
                  >
                    <div className="relative mt-1">
                      <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                          className="w-full border-none bg-gray-100 disabled:bg-gray-300 disabled:dark:bg-gray-300 text-gray-800 focus:ring-0"
                          displayValue={(item) => item}
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-600 hover:text-teal-600"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery("")}
                      >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 dark:bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {filteredModality.length === 0 && query !== "" ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              {t("nothing_found")}
                            </div>
                          ) : (
                            filteredModality.map((item) => (
                              <Combobox.Option
                                key={item.id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pr-4 ${
                                    active ? "bg-teal-600 text-white" : "text-gray-900"
                                  }`
                                }
                                value={item.value}
                              >
                                {({ templateModality, active }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        templateModality ? "font-medium" : "font-normal"
                                      }`}
                                    >
                                      {item.name}
                                    </span>
                                    {templateModality ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active ? "text-white" : "text-teal-600"
                                        }`}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>
              </div>
            </div>
            <div className="w-full h-80 border border-gray-600 dark:border-none rounded-lg my-4">
              <ReportEditor
                impression={impression}
                onChange={setCurrentReport}
                isFocused={true}
              />
            </div>
          </div>
          <div className="text-red-500 px-6 pb-6 text-left mb-4 sm:mb-0">{error}</div>
          <div className="border-t px-4 border-gray-700 py-4 flex items-center justify-between">
            <button
              onClick={onDelete}
              disabled={!selectedTemplate?.id}
              className={classNames("flex items-center space-x-3 cursor-pointer", {
                "cursor-not-allowed": !selectedTemplate?.id,
              })}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
              <p>{t("button.delete")}</p>
            </button>
            <div className="flex space-x-4">
              <Button
                isSecondary
                className="grow-1 flex space-x-2 px-6 py-4 ml-4"
                onClick={() => {
                  onClose();
                }}
                isPrimary={false}
              >
                {t("button.cancel")}
              </Button>
              <Button
                isSecondary
                className="grow-1 flex space-x-2 px-6 py-4 ml-4"
                onClick={handlePreviewModal}
                isPrimary={true}
                disabled={!templateName || !templateModality || !currentReport}
              >
                {t("button.preview_save")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <PreviewTemplateModal
        isVisible={isTemplatePreviewModal}
        currentReport={currentReport}
        onClose={onClosePreviewModal}
        isPinned={isPinned}
        refetchReports={refetchReports}
        updatedDetails={{
          ...selectedTemplate,
          template: currentReport,
          name: templateName ?? selectedTemplate?.name,
          modality: templateModality ?? selectedTemplate?.modality,
        }}
      />
    </>
  );
}

export default SaveTemplateModal;
