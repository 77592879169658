import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetDashboard = () => {
  return useMutation(["dashboard"], (params) =>
    servicesUtils.postService("/dashboard/", params)
  );
};

export default useGetDashboard;
