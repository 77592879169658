import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

function useWorkspacesData({ isOpen }) {
  return useQuery(
    "workspaceData",
    () => {
      return servicesUtils.getService("/workspaces/");
    },
    {
      enabled: isOpen,
    }
  );
}

export default useWorkspacesData;
