import React from "react";
import collapse from "../../public/assets/icons/viewer/collapse.svg";
import expand from "../../public/assets/icons/viewer/expand.svg";
import copy from "../../public/assets/icons/viewer/copy.svg";
import zoomIn from "../../public/assets/icons/viewer/zoom_in.svg";
import invert from "../../public/assets/icons/viewer/invert.svg";
import pan from "../../public/assets/icons/viewer/pan.svg";
import arrowDown from "../../public/assets/icons/viewer/arrow_down.svg";
import arrowUp from "../../public/assets/icons/viewer/arrow_up.svg";
import windowLevel from "../../public/assets/icons/viewer/window_level.svg";
import length from "../../public/assets/icons/viewer/length.svg";
import reset from "../../public/assets/icons/viewer/reset.svg";
import flipVertical from "../../public/assets/icons/viewer/flip_vertical.svg";
import flipHorizontal from "../../public/assets/icons/viewer/flip_horizontal.svg";
import rotateRight from "../../public/assets/icons/viewer/rotate_right.svg";
import rotateLeft from "../../public/assets/icons/viewer/rotate_left.svg";
import magnifyingGlass from "../../public/assets/icons/viewer/magnifying_glass.svg";
import probe from "../../public/assets/icons/viewer/probe.svg";
import stackScroll from "../../public/assets/icons/viewer/stack_scroll.svg";
import slices from "../../public/assets/icons/viewer/slices.svg";
import layout from "../../public/assets/icons/viewer/layout.svg";
import cine from "../../public/assets/icons/viewer/cine.svg";
import play from "../../public/assets/icons/viewer/play.svg";
import stop from "../../public/assets/icons/viewer/stop.svg";
import close from "../../public/assets/icons/viewer/close.svg";
import bidirectional from "../../public/assets/icons/viewer/bidirectional.svg";
import annotate from "../../public/assets/icons/viewer/annotate.svg";
import ellipse from "../../public/assets/icons/viewer/ellipse.svg";
import chevronRight from "../../public/assets/icons/viewer/chevron-right.svg";
import seriesMarking from "../../public/assets/icons/viewer/series-marking.svg";
import xrayThumbnail from "../../public/assets/icons/xray_thumbnail.svg";
import notificationBell from "../../public/assets/icons/notification-widget/notification-bell.svg";
import right from "../../public/assets/icons/notification-widget/chevron-right.svg";
import left from "../../public/assets/icons/notification-widget/chevron-left.svg";
import addUser from "../../public/assets/icons/addUsers.svg";
import addUserTeal from "../../public/assets/icons/addUsersTeal.svg";
import emergencyBell from "../../public/assets/icons/emergencyAlert.svg";
import expandArrows from "../../public/assets/icons/expandArrows.svg";
import collapseArrows from "../../public/assets/icons/collapseArrows.svg";
import report from "../../public/assets/icons/report.svg";
import license from "../../public/assets/icons/license.svg";

const ICONS = {
  collapse: collapse,
  expand: expand,
  copy: copy,
  invert: invert,
  zoomIn: zoomIn,
  pan: pan,
  arrowDown: arrowDown,
  arrowUp: arrowUp,
  windowLevel: windowLevel,
  length: length,
  reset: reset,
  flipHorizontal: flipHorizontal,
  flipVertical: flipVertical,
  rotateRight: rotateRight,
  rotateLeft: rotateLeft,
  emergencyBell: emergencyBell,
  magnifyingGlass: magnifyingGlass,
  probe: probe,
  stackScroll: stackScroll,
  slices: slices,
  layout: layout,
  cine: cine,
  play: play,
  stop: stop,
  close: close,
  ellipse: ellipse,
  annotate: annotate,
  bidirectional: bidirectional,
  chevronRight: chevronRight,
  seriesMarking: seriesMarking,
  xrayThumbnail,
  notificationBell,
  right,
  left,
  addUser,
  addUserTeal,
  expandArrows: expandArrows,
  collapseArrows: collapseArrows,
  report: report,
  license,
};
export default function getIcon(key, props) {
  if (!key || !ICONS[key]) {
    return React.createElement("div", null, "Missing Icon");
  }

  return React.createElement(ICONS[key], props);
}

export { getIcon, ICONS };
