import { get, isEmpty } from "lodash";
import classNames from "classnames";
import Image from "next/image";
import { getTrimmedId } from "./utils";
import { useTranslation } from "react-i18next";
import { EllipsisVerticalIcon, EyeSlashIcon, PlusIcon } from "@heroicons/react/20/solid";
import DropdownMenu from "../common/DropdownMenu";
import { EyeIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import useHideWorkspace from "./services/useHideWorkspace";
import useUnhideWorkspace from "./services/useUnhideWorkspace";
import Store from "../../utils/Store";
import ConfirmationModal from "../ConfirmationModal";
import useExitWorkspace from "./services/useExitWorkspace";
import Toast from "../Toast";
import useDefaultWorkspace from "./services/useDefaultWorkspace";
import { PatientsContext } from "../Patients/PatientsContext";

const WorkspaceList = (props) => {
  const {
    workspaceData,
    onClickEditWorkspace,
    onClickNewWorkspace,
    defaultId,
    refetchWorkspace,
    refetchProfile,
  } = props;
  const { t } = useTranslation();
  const { shared_workspaces: sharedWorkspaces, owned_workspaces: ownWorkspaces } =
    workspaceData;

  const { getPatientData } = useContext(PatientsContext);

  const [selectedWorkspace, setSelectedWorkspace] = useState({});

  const hideWorkspace = useHideWorkspace();
  const unhideWorkspace = useUnhideWorkspace();
  const exitWorkspace = useExitWorkspace();
  const defaultWorkspace = useDefaultWorkspace();

  const refetchAll = () => {
    refetchWorkspace();
    getPatientData();
    refetchProfile();
  };

  const sharedWorkspaceMenu = (isActive, id) => {
    if (id == defaultId) {
      return [
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {t("workspace_management.exit_workspace")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: () => {
            Store.update((s) => {
              s.isConfirmationModalOpen = true;
            });
          },
        },
      ];
    } else if (!isActive) {
      return [
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {isActive
                ? t("workspace_management.hide_patients_worklist")
                : t("workspace_management.show_patients_worklist")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: () => {
            if (isActive) {
              hideWorkspace
                .mutateAsync(selectedWorkspace?.id)
                .then(() => {
                  refetchAll();
                  Toast.success(t("workspace_management.toast.hide_success"));
                })
                .catch((err) => {
                  Toast.error(t("report_signature.toast.failed"));
                });
            } else {
              unhideWorkspace
                .mutateAsync(selectedWorkspace?.id)
                .then(() => {
                  refetchAll();
                  Toast.success(t("workspace_management.toast.unhide_success"));
                })
                .catch((err) => {
                  Toast.error(t("report_signature.toast.failed"));
                });
            }
          },
        },
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {t("workspace_management.exit_workspace")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: () => {
            Store.update((s) => {
              s.isConfirmationModalOpen = true;
            });
          },
        },
      ];
    } else {
      return [
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {t("workspace_management.set_default_upload")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: async () => {
            handleSetDefault();
          },
        },
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {isActive
                ? t("workspace_management.hide_patients_worklist")
                : t("workspace_management.show_patients_worklist")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: () => {
            if (isActive) {
              hideWorkspace
                .mutateAsync(selectedWorkspace?.id)
                .then(() => {
                  refetchAll();
                  Toast.success(t("workspace_management.toast.hide_success"));
                })
                .catch((err) => {
                  Toast.error(t("report_signature.toast.failed"));
                });
            } else {
              unhideWorkspace
                .mutateAsync(selectedWorkspace?.id)
                .then(() => {
                  refetchAll();
                  Toast.success(t("workspace_management.toast.unhide_success"));
                })
                .catch((err) => {
                  Toast.error(t("report_signature.toast.failed"));
                });
            }
          },
        },
        {
          name: (
            <div className="flex px-4 py-3 items-center justify-start text-md">
              {t("workspace_management.exit_workspace")}
            </div>
          ),
          className: "hover:bg-teal-800",
          onClick: () => {
            Store.update((s) => {
              s.isConfirmationModalOpen = true;
            });
          },
        },
      ];
    }
  };

  const onClickConfirmExit = async () => {
    exitWorkspace
      .mutateAsync(selectedWorkspace?.id)
      .then(() => {
        refetchProfile();
        getPatientData();
        refetchWorkspace();
        Toast.success("workspace_management.toast.exit");
      })
      .catch(() => {
        Toast.error(t("report_signature.toast.failed"));
      });
    Store.update((s) => {
      s.isConfirmationModalOpen = false;
    });
  };

  const handleSetDefault = async () => {
    defaultWorkspace
      .mutateAsync(selectedWorkspace?.id)
      .then(() => {
        refetchProfile();
        Toast.success(t("workspace_management.toast.default_workspace"));
      })
      .catch((resp) => {
        Toast.error(
          get(resp, ["response", "data", "detail"], t("report_signature.toast.failed"))
        );
      });
  };

  return (
    <>
      <div className="text-left p-4 flex flex-col h-full">
        <div className="!max-h-1/2">
          <div className="text-gray-800 dark:text-gray-200">
            <div>{t("workspace_management.welcome_text")}</div>
            <div className="text-sm">{t("workspace_management.definition")}</div>
          </div>
          <div className="max-h-[180px] scrollbar overflow-y-auto">
            {ownWorkspaces &&
              ownWorkspaces.map((ws) => {
                let workspaceId = get(ws, ["uuid"], "");
                workspaceId = getTrimmedId(workspaceId);
                return (
                  <div
                    key={workspaceId}
                    className="bg-gray-100 dark:bg-gray-900 p-4 rounded-md mt-5 flex items-center justify-between text-[14px] cursor-pointer text-gray-900 dark:text-gray-100"
                    onClick={() => onClickEditWorkspace(get(ws, ["id"], ""))}
                  >
                    <div className="flex items-center">
                      <span className="mr-5 uppercase">{workspaceId}</span>
                      <span>
                        {ws.name} ({ws.count} {t("members_key")})
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      {defaultId === ws.id && (
                        <p className="text-gray-400 text-md italic mr-2">
                          {t("workspace_management.default")}
                        </p>
                      )}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path d="M9.86788 1.16806C10.5997 0.435843 11.7871 0.435843 12.5189 1.16806L12.834 1.48368C13.5658 2.21597 13.5658 3.40321 12.834 4.13498L7.69861 9.273C7.47205 9.49696 7.1934 9.66102 6.88611 9.74957L4.28194 10.4944C4.06319 10.5569 3.82882 10.4944 3.66736 10.3121C3.5085 10.174 3.446 9.93967 3.5085 9.72092L4.2533 7.11675C4.34184 6.80946 4.5059 6.53082 4.72986 6.30425L9.86788 1.16806ZM11.6127 2.05191C11.3913 1.8079 10.9955 1.8079 10.7507 2.05191L9.99288 2.80946L11.1934 4.00998L11.9512 3.22873C12.196 3.00738 12.196 2.61155 11.9512 2.36754L11.6127 2.05191ZM5.45642 7.4605L5.01892 8.98394L6.54236 8.54644C6.64652 8.51779 6.73767 8.46311 6.81319 8.38759L10.3106 4.8928L9.11007 3.69228L5.61527 7.18967C5.53975 7.26519 5.48507 7.35634 5.45642 7.4605ZM5.36007 2.18446C5.70642 2.18446 5.98507 2.46441 5.98507 2.80946C5.98507 3.15582 5.70642 3.43446 5.36007 3.43446H2.4434C1.86814 3.43446 1.40173 3.90061 1.40173 4.47613V11.5595C1.40173 12.135 1.86814 12.6011 2.4434 12.6011H9.52673C10.1023 12.6011 10.5684 12.135 10.5684 11.5595V8.6428C10.5684 8.29644 10.847 8.0178 11.1934 8.0178C11.5398 8.0178 11.8184 8.29644 11.8184 8.6428V11.5595C11.8184 12.8251 10.7924 13.8511 9.52673 13.8511H2.4434C1.17778 13.8511 0.151733 12.8251 0.151733 11.5595V4.47613C0.151733 3.2105 1.17778 2.18446 2.4434 2.18446H5.36007Z" />
                      </svg>
                      {defaultId !== ws.id && (
                        <DropdownMenu
                          options={[
                            {
                              name: (
                                <div className="flex p-4 items-center justify-start text-md">
                                  {t("workspace_management.set_default_upload")}
                                </div>
                              ),
                              className: "hover:bg-teal-800",
                              onClick: () => {
                                handleSetDefault();
                              },
                            },
                          ]}
                          menuItemClassName="z-50 -top-4 right-5 !w-60"
                          Icon={() => (
                            <EllipsisVerticalIcon
                              className="w-5 h-5 cursor-pointer"
                              stroke="2"
                              aria-hidden="true"
                              onClick={() => {
                                setSelectedWorkspace(ws);
                              }}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <button
            onClick={onClickNewWorkspace}
            className="flex space-x-2 items-center border justify-center w-full rounded-lg p-3 mt-4"
          >
            <PlusIcon className="w-6 h-6" />
            <p>{t("workspace_management.new_workspace")}</p>
          </button>
        </div>
        <div className="!max-h-1/2">
          <div className="flex items-center my-5 mt-6 w-full">
            <div className="mr-2">{t("workspace_management.shared")}</div>
            <div className="h-[1px] bg-gray-700 flex-1" />
          </div>
          {isEmpty(sharedWorkspaces) && (
            <div className="flex justify-center">
              <div className="my-8 text-normal text-gray-600 w-7/12 flex items-center gap-4">
                <div>
                  <Image
                    src="/assets/icons/workspaceLight.svg"
                    alt="workspace"
                    width="30"
                    height="30"
                  />
                </div>
                <div>{t("workspace_management.no_access")}</div>
              </div>
            </div>
          )}
          <div className="min-h-[220px] max-h-[220px] h-auto overflow-y-auto scrollbar-light dark:scrollbar mb-5">
            {sharedWorkspaces &&
              sharedWorkspaces.map((ws) => {
                let workspaceId = get(ws, ["uuid"], "");
                workspaceId = getTrimmedId(workspaceId);
                return (
                  <div
                    key={workspaceId}
                    className="p-4 rounded-md mt-5 text-md flex items-center justify-between border border-gray-300 dark:border-gray-600 text-[14px]
                         text-gray-900 dark:text-white
                         "
                  >
                    <div className="flex items-center ">
                      {!ws.data_hidden ? (
                        <EyeIcon className="w-5 h-5 mr-3" aria-hidden="true" />
                      ) : (
                        <EyeSlashIcon className="w-5 h-5 mr-3" aria-hidden="true" />
                      )}
                      <span className="mr-6 uppercase">{workspaceId}</span>
                      <span>
                        {ws.name} {ws.count ? `(${ws.count} members)` : ""}
                      </span>
                    </div>
                    <div
                      className={classNames("flex items-center", {
                        hidden: !ws.owner,
                      })}
                    >
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {t("owner_key")}: {ws.owner}
                      </div>
                    </div>
                    <div className="flex items-center">
                      {defaultId === ws.id && (
                        <p className="text-gray-400 text-md italic mr-2">
                          {t("workspace_management.default")}
                        </p>
                      )}
                      <DropdownMenu
                        options={sharedWorkspaceMenu(!ws.data_hidden, ws.id)}
                        menuItemClassName="-top-3 right-6 !h-auto !w-60"
                        Icon={() => (
                          <EllipsisVerticalIcon
                            className="w-5 h-5 cursor-pointer"
                            stroke="2"
                            aria-hidden="true"
                            onClick={() => {
                              setSelectedWorkspace(ws);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <ConfirmationModal
        onClose={() => {
          Store.update((s) => {
            s.isConfirmationModalOpen = false;
          });
        }}
        className="lg:!w-1/3"
        onClickConfirm={onClickConfirmExit}
        labels={{
          title: t("workspace_management_exit_workspace"),
          label: t("workspace_management.exit_label"),
          yes: t("yes_key"),
          no: t("button.cancel"),
        }}
      />
    </>
  );
};

export default WorkspaceList;
