import { Fragment } from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { isWebView } from "../../utils";

const MediaViewer = (props) => {
  const { isOpen, onClose, url, className } = props;
  const img_name = `${url.split("?")[0]?.split("/").slice(-1)[0]}`;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 sm:bg-zinc-900 sm:bg-opacity-70 transition-opacity" />
        </Transition.Child>
        <div className="fixed flex mx-auto flex-col h-full inset-0 z-10">
          <div className="flex sm:min-h-full h-full items-center justify-center text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "flex flex-col items-center justify-center transform shadow-xl transition-all w-full h-full sm:w-fit sm:h-fit rounded-lg bg-transparent text-white",
                  {
                    [className]: !!className,
                  }
                )}
              >
                <div
                  className={classNames(
                    "bg-gray-800 w-full p-4 flex justify-between items-center",
                    { "pt-8": isWebView() }
                  )}
                >
                  <p>{img_name}</p>
                  <XMarkIcon
                    className="h-6 w-6 text-white cursor-pointer"
                    onClick={onClose}
                  />
                </div>
                <div className="p-4 bg-gray-900 h-full w-full">
                  <img
                    src={url}
                    className="!object-contain w-fit h-full sm:h-fit max-w-xs mx-auto sm:max-w-md lg:max-w-lg xl:max-w-2xl"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MediaViewer;
