import dynamic from "next/dynamic";
import Head from "next/head";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import queryString from "query-string";
import Store from "../utils/Store";
import { QueryClient, QueryClientProvider } from "react-query";
import Script from "next/script";
import Authenticator from "../components/common/Authenticator";
import { useIdleTimer } from "react-idle-timer";
import ConnectModality from "../components/ConnectModality";
import ReportTemplateModal from "../components/ReportTemplate/ReportTemplateModal";
import Logout from "../components/Logout";
import Menubar from "../components/Menubar";
import Settings from "../components/Settings/Index";
import Toast from "../components/Toast";
import "../styles/globals.css";
import { get, isEmpty } from "lodash";
import { Router } from "next/router";
import NotificationSettingsModal from "../components/NotificationSettings/NotificationSettingsModal";
import AutomatedShareModal from "../components/AutomatedShare/AutomatedShareModal";
import UserGroupModal from "../components/UserGroupManagement/UserGroupModal";
import WorkspaceManagementModal from "../components/WorkspaceManagementModal";
import { PatientsContextProvider } from "../components/Patients/PatientsContext";
import ExportMessage from "../components/ExportMessage";
import RNEnvSelector from "../components/RNEnvSelector";
import ChangeRegionModal from "../components/ChangeRegionModal/Index";
import DatadogProvider from "../components/DatadogProvider";
import "../i18n";
import constants from "../utils/constants";

const ProductFruits = dynamic(
  () => import("react-product-fruits").then((mod) => mod.ProductFruits),
  { ssr: false }
);
Router.events.on("routeChangeComplete", () => {
  window?.productFruits &&
    window?.productFruits?.pageChanged &&
    window?.productFruits?.pageChanged();
});

function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  const { user, authData } = useStoreState(Store, (store) => ({
    user: store.user,
    authData: store.userInfo
  }));
  const userInfo = { username: get(user, ["preferred_username"], null) };

  const { hideMenu, hasShare } = pageProps;
  const onIdle = () => {
    // Reloading the page when screen is idle after 7.5 Mins
    location.reload();
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: 1000 * 60 * 7.5 });

  const { isDarkMode } = useStoreState(Store, (s) => ({
    isDarkMode: s.isDarkMode,
  }));
  const onToggleThemeClick = () => {
    if (window) {
      localStorage.setItem(constants.PERSISTED.isDarkMode, !isDarkMode);
      Store.update((s) => {
        s.isDarkMode = !isDarkMode;
      });
    }
  };

  useEffect(() => {
    if (window) {
      const params = queryString.parse(window?.location?.search);
      if (params["redirectLogin"]) {
        localStorage.setItem("redirectLogin", true);
      }

      let _isDarkMode = JSON.parse(localStorage.getItem(constants.PERSISTED.isDarkMode));
      // to set default dark mode
      if (_isDarkMode === null) {
        _isDarkMode = true;
      }
      Store.update((s) => {
        s.isDarkMode = _isDarkMode;
      });
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <>
      {hasShare ? (
        <Head>
          <meta charSet="UTF-8" />
          <meta property="og:site_name" content="Qure.ai" />
          <meta property="og:title" content="Study shared with you via Qure App" />

          <meta
            name="description"
            content="View imaging studies and collaborate in real-time"
          />
          <meta
            property="og:description"
            content="View imaging studies and collaborate in real-time"
          />
          <meta
            property="og:image"
            itemProp="image"
            content="https://app.qure.ai/assets/images/sharingPreview.png"
          />
          <meta property="og:image:width" content="1070" />
          <meta property="og:image:height" content="580" />
          <meta property="og:type" content="website" />

          <meta name="keywords" content="qure, qer, qxr, stroke" />
          <meta name="author" content="Qure.ai" />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
          />
        </Head>
      ) : (
        <Head>
          <meta charSet="UTF-8" />
          <meta property="og:site_name" content="Qure.ai" />
          <meta property="og:title" content="Qure.ai" />

          <meta
            name="description"
            content="View imaging studies and collaborate in real-time"
          />
          <meta
            property="og:description"
            content="View imaging studies and collaborate in real-time"
          />
          <meta
            property="og:image"
            itemProp="image"
            content="https://app.qure.ai/assets/images/pageMeta.png"
          />
          <meta property="og:image:width" content="1070" />
          <meta property="og:image:height" content="580" />
          <meta property="og:type" content="website" />

          <meta name="keywords" content="qure, qer, qxr, stroke" />
          <meta name="author" content="Qure.ai" />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
          />
        </Head>
      )}
      <Script id="user-snap">
        {`
          window.onUsersnapCXLoad = function (api) {
            api.init({
              user: {
                app: 'qure'
              }
            });
          }

          var script = document.createElement('script');
          script.defer = 1;
          script.src = 'https://widget.usersnap.com/global/load/1fec3ff9-9d2e-4d2e-a6b9-74cabf1f548e?onload=onUsersnapCXLoad';
          document.getElementsByTagName('head')[0].appendChild(script);
        `}
      </Script>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-JPCJ0V0E2R" />
      <Script id="gtag">
        {`
          window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', 'G-JPCJ0V0E2R');
        `}
      </Script>
      <DatadogProvider user={user} authData={authData}>
        <QueryClientProvider client={queryClient}>
          <div className="h-full flex bg-gray-100 dark:bg-gray-900">
            <Authenticator>
              {global.window && !isEmpty(user) && (
                <ProductFruits
                  workspaceCode={process.env.NEXT_PUBLIC_PRODUCTFRUITS_WORKSPACE_CODE}
                  language="en"
                  user={userInfo}
                  config={{ disableLocationChangeDetection: true }}
                />
              )}
              <PatientsContextProvider>
                <div className="flex-1 min-w-0 flex flex-col w-full h-full overflow-hidden">
                  {!hideMenu && <Menubar onToggleThemeClick={onToggleThemeClick} />}
                  <Component {...pageProps} />
                </div>
                <ConnectModality />
                <Settings />
                <ReportTemplateModal />
                <ChangeRegionModal />
                <NotificationSettingsModal />
                <AutomatedShareModal />
                <WorkspaceManagementModal />
                <UserGroupModal />
              </PatientsContextProvider>
            </Authenticator>
            <Logout />
            <Toast />
            <ExportMessage />
            <RNEnvSelector />
          </div>
        </QueryClientProvider>
      </DatadogProvider>
    </>
  );
}

export default MyApp;
