import { useStoreState } from "pullstate";
import PropTypes from "prop-types";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import FINDINGS, { GROUPEDFINDINGS } from "./findingsLabels";
import { clone, get, isEmpty, set } from "lodash";
import Checkbox from "../common/Checkbox";
import { useEffect, useState } from "react";
import Toggle from "../common/Toggle";
import { useTranslation } from "react-i18next";

const FindingsModal = ({ findings, onChange }) => {
  const { t } = useTranslation();
  const [findingState, setFindingState] = useState({});
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isFindingsSettingsOpen,
  }));

  useEffect(() => {
    if (isVisible && !isEmpty(findings)) {
      setFindingState(clone(findings));
    }
  }, [isVisible]);

  const onCloseModal = () => {
    Store.update((s) => {
      s.isFindingsSettingsOpen = false;
    });
  };

  const onChangeFinding = (finding, subFinding, val) => {
    const _findingState = clone(findingState);
    set(_findingState, [finding, subFinding], val);
    setFindingState(_findingState);
    onChange(_findingState);
  };

  const onChangeMultipleFindings = (finding, subFindings, val) => {
    subFindings.map((subFinding) => {
      onChangeFinding(finding, subFinding, val);
    });
  };

  return (
    <Modal
      title={t("setting_notification")}
      onClose={onCloseModal}
      show={isVisible}
      className="flex flex-col w-full sm:w-7/12 lg:w-6/12 xl:w-5/12 !h-auto !max-h-[80%] overflow-hidden"
    >
      <div className="p-6 pb-8 overflow-y-auto scrollbar">
        <div className="font-bold mb-2 text-left">{t("findings_modal_notify")}</div>
        <div className="text-gray-700 dark:text-gray-300 text-sm text-left">
          {t("findings_modal_info")}
        </div>

        <div>
          {Object.keys(findingState).map((finding) => {
            const subFindings = findingState[finding];
            const areAllFindingsSelected = isEmpty(subFindings)
              ? false
              : Object.keys(subFindings).every(
                  (finding) => subFindings && subFindings[finding]
                );
            const groupFindings = get(GROUPEDFINDINGS, finding, {});
            let renderedFindings = [];

            return (
              <div key={finding} className="flex flex-col">
                <div className="flex my-3 justify-between">
                  <div className="font-bold">{get(FINDINGS, [finding], finding)}</div>
                  <div className="flex items-center">
                    {t("select_all")}
                    <div className="mx-4">
                      <Toggle
                        value={areAllFindingsSelected}
                        onChange={(val) =>
                          onChangeMultipleFindings(finding, Object.keys(subFindings), val)
                        }
                      />
                    </div>
                  </div>
                </div>
                {Object.keys(groupFindings).map((groupFinding) => {
                  const findingsInGroup = groupFindings[groupFinding];
                  const areAllFindingsInGroupSelected = findingsInGroup.every(
                    (finding) => {
                      return subFindings && subFindings[finding];
                    }
                  );
                  renderedFindings = renderedFindings.concat(findingsInGroup);
                  return (
                    <>
                      <Checkbox
                        key={groupFinding}
                        id={groupFinding}
                        className="w-full my-2 pr-2"
                        checked={areAllFindingsInGroupSelected}
                        onChange={(val) =>
                          onChangeMultipleFindings(finding, findingsInGroup, val)
                        }
                        label={get(FINDINGS, [groupFinding], groupFinding)}
                      />
                      <div className="flex flex-wrap px-6">
                        {findingsInGroup.map((subFinding) => {
                          return (
                            <Checkbox
                              key={subFinding}
                              id={subFinding}
                              className="w-full sm:w-1/2 my-2 pr-2"
                              checked={subFindings[subFinding]}
                              onChange={(val) =>
                                onChangeFinding(finding, subFinding, val)
                              }
                              label={get(FINDINGS, [subFinding], subFinding)}
                            />
                          );
                        })}
                      </div>
                    </>
                  );
                })}
                <div className="flex flex-wrap">
                  {Object.keys(subFindings)
                    .filter((f) => !renderedFindings.includes(f))
                    .map((subFinding) => {
                      return (
                        <Checkbox
                          key={subFinding}
                          id={subFinding}
                          className="w-full sm:w-1/2 my-2 pr-2"
                          checked={subFindings[subFinding]}
                          onChange={(val) => onChangeFinding(finding, subFinding, val)}
                          label={get(FINDINGS, [subFinding], subFinding)}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

FindingsModal.defaultProps = {
  onChange: () => {},
};

FindingsModal.propTypes = {
  findings: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default FindingsModal;
