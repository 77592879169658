import classNames from "classnames";
import Unit from "./Unit";

const TextInput = ({
  label,
  value,
  onChange,
  name,
  id,
  placeholder,
  className,
  required,
  disabled,
  type,
  inputMode,
  min,
  max,
  maxLength,
  inputRef,
  isClinicalField,
  wrapperClassName,
  inputClassName,
  labelClassName,
  parentClassName,
  unit,
  onChangeUnit,
  leftContent,
  rightContent,
  defaultValue,
  error,
  onClick,
  subLabel,
}) => {
  return (
    <>
      {!!subLabel && <p className="text-md text-left">{subLabel}</p>}
      <div
        className={classNames(
          "relative rounded-md py-3 px-2 text-gray-900 dark:text-gray-100",
          {
            "border border-gray-500": !isClinicalField,
            "opacity-70": disabled,
            [wrapperClassName]: !!wrapperClassName,
          }
        )}
      >
        {!!label && (
          <label
            className={classNames("text-md", {
              "dark:bg-gray-800 bg-gray-200 absolute left-4 -top-3 px-4":
                !isClinicalField,
              "dark:bg-transparent flex mb-5 text-base": isClinicalField,
              [labelClassName]: !!labelClassName,
            })}
          >
            {label} {!!required && <span className="text-red-500 pl-2">*</span>}
          </label>
        )}
        <div
          className={classNames("flex items-center", {
            [parentClassName]: !!parentClassName,
          })}
        >
          {leftContent && leftContent()}
          <div
            className={classNames("rounded dark:border-0 w-full flex-1", {
              "bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-none pb-2 px-3":
                isClinicalField,
              [className]: !!className,
            })}
          >
            <input
              ref={inputRef}
              type={type || "text"}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onClick={(e) => (onClick ? onClick(e) : {})}
              name={name}
              id={id}
              defaultValue={defaultValue}
              placeholder={placeholder}
              className={classNames(
                "block bg-gray-200 dark:bg-transparent w-full border-0 pb-3",
                {
                  "border-b border-gray-400 dark:border-gray-200 hover:!border-teal-500 text-center !pb-0":
                    isClinicalField,
                  "!border-red-600": isClinicalField && !!error,
                  [inputClassName]: !!inputClassName,
                }
              )}
              required={required}
              disabled={disabled}
              inputMode={inputMode}
              maxLength={maxLength}
              min={min}
              max={max}
            />
            {isClinicalField && !!error && (
              <p className="text-red-600 pt-2 font-medium text-md">{error}</p>
            )}
          </div>
          <div>{rightContent && rightContent()}</div>
          <Unit unit={unit} onChangeUnit={onChangeUnit} />
        </div>
      </div>
    </>
  );
};

export default TextInput;
