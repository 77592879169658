import { useEffect, useState } from "react";
import SelectInput from "../FormComponents/SelectInput";
import TextInput from "../FormComponents/TextInput";
import _, { cloneDeep, get, isEmpty, isEqual, set } from "lodash";
import DateTimeInput from "../FormComponents/DateTimeInput";
import classNames from "classnames";
import TextareaInput from "../FormComponents/TextareaInput";
import FileUpload from "../FormComponents/FileUpload";
// import MultiSelectInput from "../FormComponents/MultiSelectInput";
// import { createEmptyFormValueObj, validateForm } from "./services/utlis";
import NIHSSChoice from "../FormComponents/NIHSSChoice";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
// import Progressbar from "./Progressbar";
import constants from "../../utils/constants";
import Button from "../common/Button";
import BloodPressure from "../FormComponents/BloodPressure";
import BloodGlucose from "../FormComponents/BloodGlucose";
import CheckboxList from "../FormComponents/CheckboxList";
import Checkbox from "../common/Checkbox";
import { isValid } from "date-fns";
import Modal from "../common/Modal";
import RadioList from "../FormComponents/RadioList";
import { isWebView } from "../../utils";
import Store from "../../utils/Store";
import { useTranslation } from "react-i18next";

// select and multiselect --> each options array item requires id, label, value

export default function FormCreator({
  wrapperClassName,
  className,
  title,
  formSchema,
  response,
  formName,
  columns,
  onClose,
  onSave,
  patientUid,
  isIncomingForm,
  isSignatureForm,
  containerClassName,
  isButtonLoading,
}) {
  const [formResponse, setFormResponse] = useState({});
  // const [isFormDisabled, setFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showResetConfirm, toggleResetConfirm] = useState(false);

  const { t } = useTranslation();

  let score = 0;
  if (formName === constants.FORMS.STROKE_NIHSS) {
    score = Object.values(formResponse).reduce(
      (prev, cur) => Number(prev) + Number(cur),
      0
    );
  }

  const hasEmptyRequiredFields = () => {
    return formSchema.some((field) => {
      if (field.required && !formResponse[field?.id]) {
        return true;
      }
      return false;
    });
  };
  const isNonEmptyForm =
    Object.values(formResponse).some(
      (val) => !isEmpty(val) || isValid(val) || val === true
    ) && !hasEmptyRequiredFields();

  useEffect(() => {
    if (!isEmpty(formSchema)) {
      setFormResponse(response);
      setIsLoading(false);
    }
  }, [formSchema]);

  const onChange = (id, value) => {
    const _formResponse = cloneDeep(formResponse);
    set(_formResponse, [id], value);
    setFormResponse(_formResponse);
  };

  const onReset = () => {
    const _formResponse = cloneDeep(formResponse);
    Object.keys(_formResponse).forEach((key) => {
      set(_formResponse, [key], "");
    });
    setFormResponse(_formResponse);
    onCloseResetModal();
  };

  const submitHandler = () => {
    if (isNonEmptyForm) {
      onSave(formResponse);
      Store.update((s) => {
        s.isFormSelectorVisible = false;
      });
    }
  };

  const onCloseResetModal = () => {
    toggleResetConfirm(false);
  };

  // const calculateProgress = () => {
  //   if (!isEmpty(formSchema)) {
  //     const allFields = Object.values(formSchema)
  //     const emptyFields = allFields.filter((field) => {
  //       return field.value
  //     })
  //     return (emptyFields.length / allFields.length) * 100
  //   }
  //   return 0
  // }

  // const onSetNIHSSScore = (value) => {
  //   setScore(value)
  // }

  // const onToggleNIHSSScore = (isOpen) => {
  //   onReset()
  //   setFormDisabled(isOpen)
  // }

  return (
    <>
      {title && (
        <div
          className={classNames(
            "flex items-center justify-between bg-gray-200 dark:bg-gray-800 py-3 rounded-t pr-6 pl-4 text-gray-900 dark:text-white",
            {
              "pt-8": isWebView(),
            }
          )}
        >
          <div className="flex items-center cursor-pointer" onClick={onClose}>
            <ChevronLeftIcon className="h-5 w-5 mr-4" />
            <h3 className="font-semibold flex text-lg">{title}</h3>
          </div>
          <div
            className={classNames("text-md underline cursor-pointer", {
              hidden: isIncomingForm,
            })}
            onClick={() => toggleResetConfirm(true)}
          >
            Reset
          </div>
        </div>
      )}
      <div
        className={classNames(
          "relative flex flex-col h-full text-center text-base bg-gray-100 dark:bg-gray-900 rounded-t overflow-y-auto",
          { [wrapperClassName]: !!wrapperClassName }
        )}
      >
        {!isLoading && (
          <form
            action=""
            className="flex flex-col h-full grow overflow-y-auto"
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <div
              className={classNames(
                "flex flex-col h-full overflow-y-auto scrollbar px-5 sm:px-6 pt-4",
                { [containerClassName]: !!containerClassName }
              )}
            >
              {/* {
                formName === constants.FORMS.STROKE_NIHSS &&
                <NIHSSScore
                  value={score}
                  onChange={onSetNIHSSScore}
                  onToggle={onToggleNIHSSScore}
                />
              } */}
              <div
                className={classNames("grid gap-5 py-5", {
                  "grid-cols-1": columns == 1 || !columns,
                  "grid-cols-2": columns == 2,
                  "grid-cols-3": columns == 3,
                  "gap-1 pt-4 pb-0": isSignatureForm
                })}
              >
                {formSchema.map((formItem) => {
                  const fieldKey = get(formItem, ["id"], "");
                  const value = get(formResponse, [fieldKey], "");

                  if (
                    formItem.response_type === "text" ||
                    formItem.response_type === "number"
                  ) {
                    return (
                      <TextInput
                        key={fieldKey}
                        label={formItem.observation}
                        subLabel={formItem.subObservation}
                        value={value}
                        onChange={(val) => onChange(fieldKey, val)}
                        placeholder={formItem.placeholder}
                        isClinicalField
                        unit={formItem.unit}
                        inputClassName={classNames("bg-transparent", {
                          [formItem.inputClassName]: !!formItem.inputClassName,
                        })}
                        labelClassName={formItem?.labelClassName}
                        parentClassName={formItem?.parentClassName}
                        type={formItem.response_type}
                        wrapperClassName={classNames("col-span-1", {
                          "col-span-1": formItem.columnSpan == 1,
                          "col-span-2": formItem.columnSpan == 2,
                          "col-span-3": formItem.columnSpan == 3,
                          [`${formItem.wrapperClassName}`]: !!formItem.wrapperClassName,
                        })}
                        className="w-40"
                        disabled={formItem.disabled || isIncomingForm}
                        required={formItem.required}
                        bg={"bg-gray-100"}
                      />
                    );
                  }

                  if (formItem.response_type === "select") {
                    return (
                      <SelectInput
                        key={fieldKey}
                        value={value}
                        onChange={(val) => onChange(fieldKey, val)}
                        options={formItem.options}
                        isClinicalField
                        className={
                          formItem.columnSpan == 1
                            ? "col-span-1"
                            : formItem.columnSpan == 2
                            ? "col-span-2"
                            : formItem.columnSpan == 3
                            ? "col-span-3"
                            : "col-span-1"
                        }
                        label={formItem.observation}
                        disabled={formItem.disabled || isIncomingForm}
                        required={formItem.required}
                        placeholder={formItem.placeholder}
                        bg={"bg-gray-100"}
                      />
                    );
                  }

                  if (
                    formItem.response_type === "datetime" ||
                    formItem.response_type === "date"
                  ) {
                    return (
                      <DateTimeInput
                        key={fieldKey}
                        label={formItem.observation}
                        value={value}
                        isClinicalField
                        onChange={(val) => onChange(fieldKey, val)}
                        dateFormat="dd/MM/yyyy"
                        datePlaceholder="DD/MM/YYYY"
                        timeFormat="h:mm aa"
                        timePlaceholder="HH:MM aa"
                        hideTime={formItem.response_type === "date"}
                        showTimeFirst={formItem.response_type !== "date"}
                        className={
                          formItem.columnSpan == 1
                            ? "col-span-1"
                            : formItem.columnSpan == 2
                            ? "col-span-2"
                            : formItem.columnSpan == 3
                            ? "col-span-3"
                            : "col-span-1"
                        }
                        disabled={formItem.disabled || isIncomingForm}
                        required={formItem.required}
                        wrapperClassName="mb-3"
                        unit={formItem.unit}
                      />
                    );
                  }

                  if (formItem.response_type === "textarea") {
                    return (
                      <TextareaInput
                        key={fieldKey}
                        title={formItem?.title}
                        label={formItem.observation}
                        value={value}
                        isClinicalField
                        rows={formItem?.row}
                        onChange={(e) => onChange(fieldKey, e.target.value)}
                        placeholder={formItem.placeholder}
                        disabled={formItem.disabled || isIncomingForm}
                        required={formItem.required}
                        className={classNames("w-full", {
                          [formItem?.className]: !!formItem.className,
                        })}
                        inputClassName={formItem.inputClassName}
                      />
                    );
                  }

                  if (formItem.response_type === "upload") {
                    return (
                      <FileUpload
                        key={fieldKey}
                        value={value}
                        onChange={(file) => onChange(fieldKey, file)}
                        options={formItem.options}
                        placeholder={formItem.placeholder}
                        className={
                          formItem.columnSpan == 1
                            ? "col-span-1"
                            : formItem.columnSpan == 2
                            ? "col-span-2"
                            : formItem.columnSpan == 3
                            ? "col-span-3"
                            : "col-span-1"
                        }
                        inputClassName={formItem.inputClassName}
                        label={formItem.observation}
                        subLabel={formItem?.subObservation}
                        disabled={formItem.disabled || isIncomingForm}
                        required={formItem.required}
                        bg={"bg-gray-100"}
                        unit={formItem.unit}
                        patientUid={patientUid}
                      />
                    );
                  }

                  if (formItem.response_type === "multiple_choice") {
                    const options = Object.values(get(formItem, ["choices"], {})).map(
                      (choice) => ({
                        value: choice.id,
                        label: choice.choice,
                        disabled: choice.disabled,
                      })
                    );
                    return (
                      <CheckboxList
                        key={fieldKey}
                        label={get(formItem, ["observation"], "")}
                        subLabel={get(formItem, ["subObservation"], "")}
                        value={value}
                        options={options}
                        onChange={(val) => onChange(fieldKey, val)}
                        className={classNames("ml-5", {
                          "grid grid-cols-2": isSignatureForm,
                        })}
                        required={formItem.required}
                        disabled={isIncomingForm}
                      />
                    );
                  }

                  if (formItem.response_type === "choice") {
                    const options = Object.values(get(formItem, ["choices"], {})).map(
                      (choice) => ({
                        value: choice.id,
                        label: choice.choice,
                      })
                    );
                    return (
                      <RadioList
                        key={fieldKey}
                        label={get(formItem, ["observation"], "")}
                        value={value}
                        options={options}
                        name={fieldKey}
                        onChange={(val) => {
                          onChange(fieldKey, val);
                        }}
                        className="ml-5"
                        required={formItem.required}
                        disabled={isIncomingForm}
                      />
                    );
                  }

                  if (formItem.response_type === "bool") {
                    return (
                      <Checkbox
                        key={fieldKey}
                        id={fieldKey}
                        checked={value}
                        label={get(formItem, ["observation"], "")}
                        onChange={(val) => onChange(fieldKey, val)}
                        className="py-3"
                        disabled={isIncomingForm}
                      />
                    );
                  }

                  if (formItem.response_type === "blood_pressure") {
                    return (
                      <BloodPressure
                        key={fieldKey}
                        value={value}
                        title={get(formItem, ["observation"], "")}
                        onChange={(val) => onChange(fieldKey, val)}
                        id={fieldKey}
                        unit={get(formItem, ["unit"], "")}
                        className="py-3"
                        required={formItem.required}
                        disabled={isIncomingForm}
                      />
                    );
                  }

                  if (formItem.response_type === "blood_glucose") {
                    return (
                      <BloodGlucose
                        key={fieldKey}
                        value={value}
                        onChange={onChange}
                        id={fieldKey}
                        unit={get(formItem, ["unit"], "")}
                        onChangeUnit={() => {}}
                        required={formItem.required}
                        disabled={isIncomingForm}
                      />
                    );
                  }

                  if (formItem.response_type === "nihss_choice") {
                    return (
                      <NIHSSChoice
                        key={fieldKey}
                        choices={get(formItem, ["choices"], {})}
                        label={get(formItem, ["observation"], "")}
                        description={get(formItem, ["description"], "")}
                        value={value}
                        number={fieldKey}
                        onChange={(value) => onChange(fieldKey, value)}
                        disabled={isIncomingForm}
                      />
                    );
                  }
                })}
              </div>
            </div>

            {/* Footer */}
            <div className="w-full flex items-center justify-between p-4 px-6 dark:bg-gray-800 space-x-5 dark:border-0 border-t border-gray-600">
              <div>
                {formName === constants.FORMS.STROKE_NIHSS && `Total score: ${score}`}
              </div>
              <div
                className={classNames({
                  "flex justify-end space-x-4 w-full border-t border-gray-700 !pt-4":
                    isSignatureForm,
                })}
              >
                {isSignatureForm && (
                  <Button
                    isSecondary
                    className="grow-1 flex space-x-2 px-6 py-3 ml-4"
                    onClick={onClose}
                    isPrimary={false}
                    disabled={isButtonLoading}
                  >
                    {t("button.cancel")}
                  </Button>
                )}
                <Button
                  isPrimary
                  disabled={
                    !isNonEmptyForm ||
                    isIncomingForm ||
                    isEqual(response, formResponse) ||
                    isButtonLoading
                  }
                  isLoading={isButtonLoading}
                  onClick={submitHandler}
                  className="px-8 !py-3"
                >
                  {isSignatureForm ? t("button.save") : t("button.submit")}
                </Button>
              </div>
            </div>
          </form>
        )}

        {isLoading && (
          <div
            className={classNames(
              "absolute h-full w-full z-20 bg-opacity-50 flex items-center justify-center bg-gray-500",
              {
                [className]: !!className,
              }
            )}
          >
            <div className="h-8 w-8 border-dotted rounded-full border-gray-500 border-[5px] animate-spin"></div>
          </div>
        )}
      </div>
      <Modal
        show={showResetConfirm}
        onClose={onCloseResetModal}
        title="Reset this form?"
        className="w-full sm:w-5/12 lg:w-4/12"
        closeOnClickAway
      >
        <div className="p-6">
          Reseting this form will delete all the inputs entered. Proceed to reset?
        </div>
        <div className="flex items-center flex-col sm:justify-end sm:flex-row p-4">
          <Button
            isSecondary
            className="grow-1 ml-4 w-full sm:w-11"
            onClick={onCloseResetModal}
          >
            Cancel
          </Button>
          <Button
            isPrimary
            className="mt-3 sm:mt-0 grow-1 ml-4 w-full sm:w-11"
            onClick={onReset}
          >
            Reset
          </Button>
        </div>
      </Modal>
    </>
  );
}
