import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const NIHSSChoice = (props) => {
  const { choices, label, description, value, number, onChange, disabled } = props;
  let gradiantClass = "";
  if (value) {
    const totalCounts = Object.values(choices).length;
    const selected = Number(value) + 1;
    if (totalCounts / selected === totalCounts) {
      gradiantClass = "border-green-700 from-green-900";
    } else if (totalCounts / selected > 1) {
      gradiantClass = "border-yellow-700 from-yellow-900";
    } else if (totalCounts / selected <= 1) {
      gradiantClass = "border-red-500 from-red-900";
    }
  }

  const onClickOption = (newValue) => {
    let _value = "";
    if (value != newValue) {
      _value = newValue;
    }
    onChange(_value);
  };

  return (
    <div
      className={classNames("flex w-full font-normal", {
        "opacity-40": disabled,
      })}
    >
      <div className="w-6 flex justify-end">{number}</div>
      <div className="flex flex-col ml-3 w-full">
        <div
          className={classNames("flex justify-between mb-5 pr-3 bg-gradient-to-l", {
            "border-r-2 opacity-70": !!gradiantClass,
            [gradiantClass]: !!gradiantClass,
          })}
        >
          <div className="flex flex-col">
            <div className="flex">{label}</div>
            <div className="text-gray-600 text-sm text-left">{description}</div>
          </div>
          <div className="flex items-center">
            {value}
            {!value && <div className="text-sm italic text-gray-600">Select score</div>}
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          {Object.values(choices).map((choiceObj, index) => {
            const { choice, score } = choiceObj;
            const currentValue = String(score);
            const isSelected = value === currentValue;

            return (
              <div
                key={currentValue}
                onClick={() => !disabled && onClickOption(currentValue)}
                className={classNames(
                  "relative flex flex-col justify-evenly border border-teal-500 rounded-lg w-[80px] h-[80px] cursor-pointer select-none",
                  {
                    "bg-teal-900 border-teal-900": isSelected,
                    "text-gray-400 border-teal-900": value,
                  }
                )}
              >
                {isSelected && (
                  <XMarkIcon className="h-4 w-4 text-teal-500 bg-teal-900 rounded-full absolute -top-2 -right-2" />
                )}
                <div className="text-gray-100">{currentValue}</div>
                <div className="text-sm text-gray-100">{choice}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NIHSSChoice;
