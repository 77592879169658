import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { get } from "lodash";
import { CheckIcon } from "@heroicons/react/24/outline";

const SelectInput = ({
  label,
  className,
  options = [],
  value,
  onChange,
  disabled,
  required,
  placeholder,
  multiple,
  isClinicalField,
}) => {
  const selected = multiple
    ? value.map((opt) => options?.find((op) => (op.value = opt)))
    : options?.find((opt) => opt.value === value);
  const selectedLabel = multiple
    ? `${selected.length} selected`
    : get(selected, ["label"], placeholder);

  return (
    <div
      className={classNames("relative flex items-center rounded-md ", {
        "opacity-50": disabled,
        "bg-gray-800 mt-7": isClinicalField,
        "border border-gray-500": !isClinicalField,
        [className]: !!className,
      })}
    >
      <Listbox
        multiple={multiple}
        disabled={disabled}
        value={selected}
        onChange={(obj) => onChange(obj.value)}
      >
        {({ open }) => (
          <>
            {!!label && (
              <Listbox.Label className={classNames("text-md", {
                "dark:bg-gray-800 bg-gray-200 absolute left-4 -top-3 px-4": !isClinicalField,
                "dark:bg-transparent flex mb-5 text-base absolute left-0 -top-7 pt-1": isClinicalField,
              })}>
                {label} {!!required && <span className="text-red-500 pl-2">*</span>}
              </Listbox.Label>
            )}
            <div className="relative w-full h-full">
              <Listbox.Button
                className={classNames(
                  "relative w-full h-full rounded-md py-2 pl-3 text-left cursor-pointer",
                  {
                    "cursor-not-allowed": disabled,
                    "h-6": !selectedLabel,
                    "!py-3": isClinicalField
                  }
                )}
              >
                <span
                  className={classNames("block truncate", {
                    "text-gray-400": !selected,
                  })}
                >
                  {selectedLabel}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 dark:bg-gray-700 text-white text-base shadow-lg border border-gray-600 text-left">
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={option?.id || index}
                      className={({ selected }) =>
                        classNames(
                          "flex justify-between relative cursor-default select-none py-2 px-3 hover:bg-teal-800",
                          {
                            "bg-gray-600": selected,
                          }
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="block truncate">{option?.label}</div>
                          {multiple && selected && (
                            <CheckIcon className="h-5 w-5 text-teal-400" />
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectInput;
