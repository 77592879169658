/* eslint-disable */
import React, { useEffect, useState } from "react";
import _, { clone, cloneDeep, countBy, get, isEmpty, set } from "lodash";
import PropTypes from "prop-types";
import useNotificationSettings from "./hooks/useNotificationSettings";
import useSaveNotificationSettings from "./hooks/useSaveNotificationSettings";
import Toggle from "../common/Toggle";
import Checkbox from "../common/Checkbox";
import Button from "../common/Button";
import settingsConfig from "./settingsConfig";
import constants from "../../utils/constants";
import classNames from "classnames";
import FindingsModal from "./FindingsModal";
import Store from "../../utils/Store";
import Toast from "../Toast";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

const NotificationSettings = (props) => {
  const { t } = useTranslation();
  const { isModal, isVisible, onClose } = props;
  const [settings, setSettings] = useState(settingsConfig);
  const saveNotificationSettings = useSaveNotificationSettings();
  const { data: settingsObj } = useNotificationSettings(!isModal || isVisible);
  const preferences = get(settingsObj, ["preferences"], {});
  const queryClient = useQueryClient();
  // const notifications = get(settingsObj, ['notifications_settings'], {})

  useEffect(() => {
    if (!isEmpty(preferences)) {
      initialize(preferences);
    }
  }, [preferences]);

  const initialize = (_preferences) => {
    const { NOTIFICATION_EVENTS } = constants;
    const { notifications } = _preferences;
    const _settings = cloneDeep(settings);
    const { aiFindings, scansAndReporting } = _settings;
    aiFindings.value =
      get(notifications, [NOTIFICATION_EVENTS.POSITIVE_FINDINGS], false) ||
      get(notifications, [NOTIFICATION_EVENTS.NEGATIVE_FINDINGS], false);
    scansAndReporting.value =
      get(notifications, [NOTIFICATION_EVENTS.NEW_PATIENT], false) ||
      get(notifications, [NOTIFICATION_EVENTS.REPORT_UPDATE], false) ||
      get(notifications, [NOTIFICATION_EVENTS.STROKE_INITIATED], false) ||
      get(notifications, [NOTIFICATION_EVENTS.PATIENT_SHARED], false);

    const _radio = aiFindings?.radio;
    const {
      all: allFindingsCount,
      selected: selectedFindingsCount,
      findings,
    } = getFindingsDetails(_preferences);

    if (aiFindings?.value) {
      if (
        get(notifications, [NOTIFICATION_EVENTS.POSITIVE_FINDINGS], false) &&
        get(notifications, [NOTIFICATION_EVENTS.NEGATIVE_FINDINGS], false)
      ) {
        _radio[0].value = true;
      } else if (get(notifications, [NOTIFICATION_EVENTS.POSITIVE_FINDINGS], false)) {
        _radio[1].value = true;
      }
    }
    _radio[1].selectSpecific = _radio[1].value
      ? allFindingsCount != selectedFindingsCount
      : false;
    _radio[1].findings = findings;
    _radio[1].selectedFindingsCount = selectedFindingsCount;

    if (scansAndReporting?.value) {
      const _check = scansAndReporting?.check;
      _check[0].value = get(notifications, [NOTIFICATION_EVENTS.NEW_PATIENT], false);
      _check[1].value = get(notifications, [NOTIFICATION_EVENTS.REPORT_UPDATE], false);
      _check[2].value = get(notifications, [NOTIFICATION_EVENTS.STROKE_INITIATED], false);
      _check[3].value = get(notifications, [NOTIFICATION_EVENTS.PATIENT_SHARED], false);
    }

    setSettings({ ..._settings });
  };

  const getFindingsDetails = (_preferences) => {
    if (!isEmpty(_preferences)) {
      const { qxr, qer, qct } = _preferences;
      const allFindings = { ...qxr, ...qer, ...qct };
      const allFindingsCount = Object.keys(allFindings).length;
      const selectedFindings = countBy(allFindings, (finding) => finding);
      const selectedFindingsCount = get(selectedFindings, ["true"], 0);
      return {
        findings: { qxr, qer, qct },
        all: allFindingsCount,
        selected: selectedFindingsCount,
      };
    }
  };

  const onCloseModal = () => {
    queryClient.invalidateQueries(["notificationSettings"]);
    onClose();
    if (isModal) {
      setSettings({});
    }
  };

  const buildPayload = () => {
    const { NOTIFICATION_EVENTS } = constants;
    const notifications = {};
    let findings = {};
    const { scansAndReporting, aiFindings } = settings;
    notifications[NOTIFICATION_EVENTS.NEW_PATIENT] =
      scansAndReporting.value && get(scansAndReporting, ["check", 0, "value"], false);
    notifications[NOTIFICATION_EVENTS.REPORT_UPDATE] =
      scansAndReporting.value && get(scansAndReporting, ["check", 1, "value"], false);
    notifications[NOTIFICATION_EVENTS.STROKE_INITIATED] =
      scansAndReporting.value && get(scansAndReporting, ["check", 2, "value"], false);
    notifications[NOTIFICATION_EVENTS.PATIENT_SHARED] =
      scansAndReporting.value && get(scansAndReporting, ["check", 3, "value"], false);
    notifications[NOTIFICATION_EVENTS.NEGATIVE_FINDINGS] =
      aiFindings.value && get(aiFindings, ["radio", 0, "value"]);
    notifications[NOTIFICATION_EVENTS.POSITIVE_FINDINGS] =
      aiFindings.value &&
      (get(aiFindings, ["radio", 0, "value"]) || get(aiFindings, ["radio", 1, "value"]));
    findings = get(aiFindings, ["radio", 1, "findings"], {});

    // if all qure findings (positive + negative) are selected then by default enabled all the usecase specific findings
    if (get(aiFindings, ["radio", 0, "value"], false)) {
      Object.keys(findings).forEach((finding) => {
        const subFindings = findings[finding];
        Object.keys(subFindings).forEach((subFinding) => {
          subFindings[subFinding] = true;
        });
      });
    }
    return { notifications, ...findings };
  };

  const onSave = () => {
    const payload = buildPayload();
    saveNotificationSettings
      .mutateAsync(payload)
      .then(() => {
        Toast.success(t("notification_modal.toast.saved_successfully"));
        onCloseModal();
      })
      .catch(() => {
        Toast.error(t("notification_modal.toast.saved_failed"));
      });
  };

  const onSelectAll = (category, value) => {
    const _settings = clone(settings);
    const _category = get(_settings, [category], {});
    set(_category, ["value"], value);

    if (_category.check) {
      _category.check.forEach((opt) => {
        opt.value = get(_category, ["value"]);
      });
    }

    if (_category.radio) {
      if (value) {
        _category.radio[0].value = true;
      } else {
        _category.radio.forEach((opt) => {
          opt.value = false;
        });
      }
    }

    setSettings(_settings);
  };

  const onChangeSetting = (category, control, index, val) => {
    const _settings = cloneDeep(settings);
    if (control === "check") {
      set(_settings, [category, control, index, "value"], val);
    }
    if (control === "radio") {
      const _radio = get(_settings, [category, control]);
      if (_radio) {
        _radio.forEach((opt, idx) => {
          opt.value = idx === index;
          if (!opt.value && opt.selectSpecific) {
            opt.selectSpecific = false;
          }
        });
      }
    }
    setSettings(_settings);
  };

  const onSelectSpecific = (category, control, index, val) => {
    const _settings = cloneDeep(settings);
    set(_settings, [category, control, index, "selectSpecific"], val);
    setSettings(_settings);
  };

  const onChangeFindings = (newFindings) => {
    const _settings = clone(settings);
    set(_settings, ["aiFindings", "radio", "1", "findings"], newFindings);
    setSettings(_settings);
  };

  return (
    <>
      <div className="sm:py-5 overflow-y-auto h-full">
        <div className="sm:hidden text-gray-900 dark:text-white font-medium text-xl px-6 mb-6">
          {t("setting_notification")}
        </div>
        {Object.keys(settings).map((category, index) => {
          const {
            title,
            description,
            value: isCategoryEnabled,
            radio,
            check,
          } = settings[category];
          if (window.electronAPI && category !== "aiFindings") {
            return null;
          }
          return (
            <div
              className={classNames("", {
                "border-t border-gray-700 mt-4 pt-4": index > 0,
              })}
            >
              <div className="flex justify-between bg-gray-800 sm:bg-transparent px-6 py-3 mb-5 sm:mb-3">
                <div className="">
                  <div className="flex text-gray-800 dark:text-white text-base font-bold">
                    {t(title)}
                  </div>
                  <div className="hidden sm:block text-gray-800 dark:text-gray-300 text-sm">
                    {t(description)}
                  </div>
                </div>
                <div className="flex items-center justify-center h-6">
                  <Toggle
                    value={isCategoryEnabled}
                    onChange={(val) => onSelectAll(category, val)}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start px-6">
                {category === "scansAndReporting" && (
                  <div className="py-1 dark:text-white">
                    {t("notification_modal.notify_me_for")}...
                  </div>
                )}
                {!_.isEmpty(radio) && (
                  <fieldset className="">
                    {radio.map((opt, index) => {
                      const { title, value, findings, selectSpecific } = opt;
                      const findingDtl = getFindingsDetails(findings);
                      const selectedFindingsCount = get(findingDtl, ["selected"], 0);
                      return (
                        <>
                          <div
                            key={index}
                            className={classNames("flex items-center py-2", {
                              "opacity-50": !isCategoryEnabled,
                            })}
                          >
                            <input
                              id={`${category}-radio-${index}`}
                              name="aiFindings"
                              type="radio"
                              checked={value}
                              disabled={!isCategoryEnabled}
                              onChange={(e) =>
                                onChangeSetting(category, "radio", index, e.target.value)
                              }
                              className="h-5 w-5 dark:border-gray-300 text-teal-400 bg-transparent cursor-pointer"
                            />
                            <label
                              htmlFor={`${category}-radio-${index}`}
                              className="ml-3 dark:text-gray-200 cursor-pointer"
                            >
                              {t(title)}
                            </label>
                          </div>
                          {!isEmpty(findings) && (
                            <div className="flex items-center justify-center">
                              <Checkbox
                                id="select-specific"
                                className="ml-5"
                                disabled={!value}
                                checked={selectSpecific}
                                onChange={(val) =>
                                  onSelectSpecific(category, "radio", index, val)
                                }
                                label="Specific findings:"
                              />
                              <div
                                className={classNames(
                                  "text-teal-400 hover:underline ml-2 cursor-pointer",
                                  {
                                    "opacity-50": !!value && !selectSpecific,
                                    "cursor-not-allowed": !selectSpecific,
                                  }
                                )}
                                onClick={() => {
                                  if (!!value && selectSpecific) {
                                    Store.update((s) => {
                                      s.isFindingsSettingsOpen = true;
                                    });
                                  }
                                }}
                              >
                                {selectedFindingsCount} {t("selected_key")}
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </fieldset>
                )}
                {!_.isEmpty(check) &&
                  check.map((opt, index) => (
                    <Checkbox
                      id={`${category}-check-${index}`}
                      label={t(opt.title)}
                      checked={opt.value}
                      onChange={(val) => onChangeSetting(category, "check", index, val)}
                      disabled={!isCategoryEnabled}
                      className={classNames("py-2", {
                        "opacity-50": !isCategoryEnabled,
                      })}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center flex-row justify-end p-4 border-t border-t-gray-600">
        <Button isSecondary className="grow-1 w-full sm:w-40" onClick={onCloseModal}>
          {t("button.cancel")}
        </Button>
        <Button
          isPrimary
          isLoading={saveNotificationSettings.isLoading}
          onClick={onSave}
          className="sm:mt-0 grow-1 ml-4 w-full sm:w-40"
        >
          {t("button.save_changes")}
        </Button>
      </div>
      <FindingsModal
        findings={get(settings, ["aiFindings", "radio", "1", "findings"], {})}
        onChange={onChangeFindings}
      />
    </>
  );
};

NotificationSettings.defaultProps = {
  onClose: () => {},
  isModal: false,
  isVisible: false,
};

NotificationSettings.propTypes = {
  onClose: PropTypes.func,
  isModal: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default NotificationSettings;
