import { Disclosure, Tab, Transition } from "@headlessui/react";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import Preview from "./Preview";
import Tag from "./Tag";
import TagSettingsMenu from "./TagSettingsMenu";
import { useTranslation } from "react-i18next";

const PatientWorklistMenu = (props) => {
  const {
    userSettingsConf,
    setUserSettingsConf,
    initialUserSettingsConf,
    labelMappings,
    tagsList,
  } = props;

  const { t } = useTranslation();
  const [currentDisclosure, setCurrentDisclosure] = useState({
    studyName: "qxr",
    tagNo: null,
  });

  const qxrTags = tagsList.qxr
    ? get(userSettingsConf, ["worklist", "tags", "qxr"], null)
    : null;
  const qerTags = tagsList.qer
    ? get(userSettingsConf, ["worklist", "tags", "qer"], null)
    : null;
  const qctTags = tagsList.qct
    ? get(userSettingsConf, ["worklist", "tags", "qct"], null)
    : null;

  const allStudies = [
    qxrTags && { studyName: "qxr", tags: qxrTags },
    qerTags && { studyName: "qer", tags: qerTags },
    qctTags && { studyName: "qct", tags: qctTags },
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="space-y-3 mb-5">
        <h3 className="flex items-center text-gray-800 dark:text-gray-100 font-semibold">
          {t("worklist_tags.selection")}
        </h3>
        <h5 className="flex items-center text-gray-700 dark:text-gray-200 text-[12px] text-left">
          {t("worklist_tags.tags_text")}
        </h5>
      </div>
      <Tab.Group
        as={"div"}
        className="border rounded-md border-gray-400 dark:border-gray-500 grow flex flex-col overflow-hidden"
      >
        <Tab.List
          as="div"
          className={
            "border-b border-gray-300 dark:border-b-gray-500 flex items-center px-5 text-[12px] text-gray-800 dark:text-gray-100 font-semibold"
          }
        >
          {allStudies.map(
            (study) =>
              study && (
                <Tab
                  as="div"
                  key={study.studyName}
                  className={
                    "w-28 pt-3 px-4 mx-2 pb-2 border-b-4 border-b-gray-100 dark:border-gray-800 dark:ui-selected:border-teal-400 ui-selected:border-teal-400 cursor-pointer"
                  }
                  onClick={() =>
                    setCurrentDisclosure({
                      studyName: study.studyName,
                      tagNo: null,
                    })
                  }
                >
                  {study.studyName === "qxr"
                    ? "X-RAY"
                    : study.studyName === "qer"
                    ? "HEAD CT"
                    : "LUNG CT"}
                </Tab>
              )
          )}
        </Tab.List>
        <Tab.Panels className={"text-gray-800 dark:text-gray-200 grow flex flex-col"}>
          {allStudies.map((study) => {
            if (!study) return "";
            const { tags } = study;
            const allTags = [
              { tagName: "Tag 01", value: tags.tag1 },
              { tagName: "Tag 02", value: tags.tag2 },
            ];
            return (
              <Tab.Panel
                as="div"
                className={classNames("flex flex-col grow")}
                key={study.studyName}
              >
                {allTags.map((tag, index) => {
                  const { studyName } = study;
                  const { tagName } = tag;
                  const tagNo = `tag${index + 1}`;
                  return (
                    <Disclosure key={tagNo}>
                      {({ open, close }) => {
                        if (currentDisclosure.tagNo !== tagNo && open) close();

                        return (
                          <div
                            className={classNames(
                              "flex flex-col border-b border-gray-300 dark:border-gray-500",
                              {
                                "h-full": open,
                                "!border-b-0":
                                  tagNo === "tag2" && !!currentDisclosure.tagNo,
                              }
                            )}
                          >
                            <Disclosure.Button
                              as="div"
                              className={classNames("w-full h-max")}
                              onClick={() =>
                                setCurrentDisclosure({
                                  studyName: studyName,
                                  tagNo: currentDisclosure.tagNo === tagNo ? null : tagNo,
                                })
                              }
                            >
                              <Tag
                                key={index}
                                tagNo={tagNo}
                                studyName={studyName}
                                userSettingsConf={userSettingsConf}
                                initialUserSettingsConf={initialUserSettingsConf}
                                labelMappings={labelMappings}
                                tagsList={tagsList}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="div" className="h-[1px] grow pb-3">
                              <TagSettingsMenu
                                tagNo={tagNo}
                                studyName={studyName}
                                userSettingsConf={userSettingsConf}
                                setUserSettingsConf={setUserSettingsConf}
                                labelMappings={labelMappings}
                                tagsList={tagsList}
                              />
                            </Disclosure.Panel>
                          </div>
                        );
                      }}
                    </Disclosure>
                  );
                })}
                {isEmpty(currentDisclosure.tagNo) && (
                  <div className="px-5 pb-5 mt-auto">
                    <Preview
                      tags={tags}
                      labelMappings={labelMappings}
                      tagsList={tagsList}
                    />
                  </div>
                )}
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default PatientWorklistMenu;
