import React, { useEffect } from "react";
import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config'
import { isEmpty, get } from "lodash";

class DatadogProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  initialiseDatadog = () => {
    const { publicRuntimeConfig } = getConfig();
    const commitHash = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.substring(0,7);
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      version:`${publicRuntimeConfig?.version}.${commitHash}`,
      env:window.location.hostname,
      sessionSampleRate:100,
      sessionReplaySampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      //trackViewsManually: true,
      defaultPrivacyLevel:'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }

  componentDidMount() {
    if(process.env.NEXT_PUBLIC_APP_ENV === "production") {
      this.initialiseDatadog();
    }
  }

  componentDidUpdate(prevProps) {
    const{ user: prevUser = {}}= prevProps
    const {user = {}, authData = {}} = this.props
    const organizations = get(authData, "authData", {});
    const sources = get(authData, "sources", []);

    if(prevUser.email !== user.email && process.env.NEXT_PUBLIC_APP_ENV === "production") {
      datadogRum.setUser({
        name: user.name && user.name !== "undefined" ? user.name : user.email,
        email: user.email,
        language: navigator?.language,
        sources: sources?.map((source) => {
          if(isEmpty(organizations[source])) {
            return source;
          }
          return organizations[source];
        })
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    if(process.env.NEXT_PUBLIC_APP_ENV === "production") {
      datadogRum.addError(
        error,
        {
          scope: "error-boundary"
        },
        "source"
      );
    }
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />
    }
    return this.props.children
  }
}

const Fallback = () => {
  return(
    <div className="flex h-full bg-black flex-col justify-center items-center">
      <div className="text-white">
        Application error: a client-side exception has occurred (please reload the browser)
      </div>
    </div>
  )
}

export default DatadogProvider;
