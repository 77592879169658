import { useStoreState } from "pullstate";
import NotificationSettings from ".";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";

const NotificationSettingsModal = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isNotificationSettingsOpen,
  }));

  const onCloseModal = () => {
    Store.update((s) => {
      s.isNotificationSettingsOpen = false;
    });
  };

  return (
    <Modal
      title={t("setting_notification")}
      onClose={onCloseModal}
      show={isVisible}
      className="flex flex-col w-full sm:w-7/12 lg:w-6/12 xl:w-4/12 !h-auto !max-h-[80%] overflow-hidden"
    >
      <NotificationSettings isModal isVisible={isVisible} onClose={onCloseModal} />
    </Modal>
  );
};

export default NotificationSettingsModal;
