import axiosConnect from "../../../utils/axiosConnect";
// import { get } from "lodash";

const prepareUpload = (data) => {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    if (key === "files") {
      data.files.forEach((file) => {
        formData.append(file.name, file);
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return formData;
};

const useAddSignature = (data, config = {}) => {
  const serviceConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
    timeout: 300000,
    ...config,
  };
  const formDatas = prepareUpload(data);
  return axiosConnect
    .post(`profile/settings/report/ `, formDatas, serviceConfig)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
};

export default useAddSignature;
