import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useStoreState } from "pullstate";
import React from "react";
import classNames from "classnames";
import useMedia, { MediaSizes } from "../hooks/useMedia";
import onDownloadApp from "../utils/downloadApp";
import Store from "../utils/Store";
import Button from "./common/Button";
import Modal from "./common/Modal";
import ConfigureDCMIO from "./ConfigureDCMIO";
import { useTranslation } from "react-i18next";

const ConnectModality = () => {
  const { t } = useTranslation();
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isConnectModalityOpen,
  }));

  const getIP = () => {
    try {
      return window.electronAPI.getIP();
    } catch (err) {
      return "Refer to System Settings";
    }
  };
  const DesktopAppInstructions = () => {
    return (
      <div className="flex flex-col justify-between h-full pt-6 pb-3 text-left">
        <div className="h-full pb-6 px-6">
          <p>{t("connection_modality.enter_message")}</p>

          <div className="py-6">
            <div className="py-2 flex ">
              <div className="min-w-[100px] text-start">AE Title:</div>
              <div className="bg-gray-700 rounded text-center px-3">QUREAI</div>
            </div>
            <div className="py-2 flex">
              <div className="min-w-[100px] text-start">IP:</div>
              <div className="bg-gray-700 rounded text-center px-3">{getIP()}</div>
            </div>
            <div className="py-2 flex">
              <div className="min-w-[100px] text-start">Port:</div>
              <div className="bg-gray-700 rounded text-center px-3">5253</div>
            </div>
          </div>

          <div className="text-md rounded border border-gray-700 p-4">
            {t("connection_modality.note")}
            <br />
            {t("connection_modality.not_work")}
          </div>
          <ConfigureDCMIO />
        </div>
        <div className="px-6">
          <Button
            isSecondary
            className="grow-1 !px-5 py-3"
            onClick={() => (window.location = "mailto:support@qure.ai")}
          >
            <QuestionMarkCircleIcon className="h-6 w-6 mr-3" />{" "}
            {t("connection_modality.request_support")}
          </Button>
        </div>
      </div>
    );
  };

  const OtherInstructions = () => (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="p-6 text-left text-gray-600 dark:text-gray-300">
        <p className="pb-5 font-normal">{t("connection_modality.use_message")}</p>
        <ConfigureDCMIO />
      </div>

      {!isMobile && (
        <div className="flex items-center justify-end border-t border-t-gray-600 w-full p-4">
          <Button
            isSecondary
            onClick={() => {
              window.open("/open-app", "_blank");
            }}
            className="grow-1 px-6 py-4 hidden"
          >
            {t("connection_modality.launch_desktop_app")}
          </Button>

          <a
            href="/open-app"
            target="_blank"
            className="text-gray-600 dark:text-white px-4"
          >
            {t("installed_qure_app")}
          </a>

          <Button className="grow-1 px-6 py-4 ml-4" onClick={onDownloadApp} isPrimary>
            {t("connection_modality.download_qure_app")}
          </Button>
        </div>
      )}
    </div>
  );

  const onClose = () => {
    Store.update((s) => {
      s.isConnectModalityOpen = false;
    });
  };

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      title={t("setting_connection")}
      className={classNames("w-full", {
        "sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12": window.electronAPI,
        "sm:w-9/12 md:w-8/12 lg:w-4/12 xl:w-4/12": !window.electronAPI,
      })}
      closeOnClickAway
    >
      {window.electronAPI ? <DesktopAppInstructions /> : <OtherInstructions />}
    </Modal>
  );
};

export default ConnectModality;
