import {useQuery} from "react-query";
import servicesUtils from "../../../utils/servicesUtils";


function useSingleWorkspace({isOpen, id}){
    return useQuery('singleWorkspace', ()=>{
        return servicesUtils.getService(`/workspaces/${id}`)
    }, {
        enabled: isOpen && !!id
    })
}

export default useSingleWorkspace