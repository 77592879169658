import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useNotificationSettings = (isOpen) => {
  return useQuery(
    ["notificationSettings", isOpen],
    () => {
      return servicesUtils.getService("/userpreferences/");
    },
    {
      enabled: isOpen,
      staleTime: 0,
    }
  );
};

export default useNotificationSettings;
