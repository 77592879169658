const FINDINGS = {
  // main headers
  qxr: "Chest X-ray scans (qXR)",
  qer: "Brain NCCT scans (qER-Quant)",
  qct: "Chest CT Scans {qCT}",
  qmsk: "Fractures",
  forms: "Forms submitted",
  // group headers
  emergent_findings: "Emergent findings",
  other_findings: "Other findings",
  tags: "Tags",
  intra_cranial_hemorrhage : "Intra-cranial hemorrhage",
  // qxr
  abnormal: "Abnormal",
  atelectasis: "Atelectasis",
  bluntedcp: "Bluntedcp",
  calcification: "Calcification",
  cardiomegaly: "Cardiomegaly",
  cavity: "Cavity",
  consolidation: "Consolidation",
  degenspine: "Degenspine",
  diaphragm: "Diaphragm",
  fibrosis: "Fibrosis",
  fracture: "Fracture",
  hilarlymphadenopathy: "Hilar lymphadenopathy",
  nodule: "Nodule",
  opacity: "Opacity",
  peffusion: "Peffusion",
  pneumoperitoneum: "Pneumoperitoneum",
  reticulonodularpattern: "Reticulonodular pattern",
  ribfracture: "Ribfracture",
  scoliosis: "Scoliosis",
  trachealshift: "Trachealshift",
  tuberculosis: "Tuberculosis",
  lung_nodule_malignancy: "Lung nodule malignancy",
  qmsk_fracture: "qMSK fracture",
  // qer
  ASPECTS: "ASPECT Score <10",
  ATROPHY: "Cerebral atrophy",
  EDH: "Extradural hemorrhage",
  FRACTURE: "Cranial fracture",
  ICH: "Intracranial hemorrhage",
  INFARCT: "Hypdensity suggestive of infarct",
  IPH: "Intraparenchymal hemorrhage",
  IVH: "Intraventricular hemorrhage",
  ME: "Mass effect",
  MLS: "Midline shift",
  SAH: "Subarachnoid hemorrhage",
  SDH: "Subdural hemorrhage",
  // qct
  lungnodule: "Lung Nodule",
  // forms
  tb_voucher: "TB Voucher",
  symptoms: "Symptoms",
  qtrack_cbnaat_clt: "Confirmatory Lab Test - CBNAAT",
  qtrack_genexpert_clt: "Confirmatory Lab Test - GeneXpert",
  risk_groups: "Risk Groups",
  nihss: "NIHSS Evaluation",
  stroke_clinical: "Clinical History of Stroke Suspects",
};

export const GROUPEDFINDINGS = {
  qxr: {
    emergent_findings: [
      "opacity",
      "consolidation",
      "peffusion",
      "fibrosis",
      "atelectasis",
      "ribfracture",
      "pneumoperitoneum"
    ],
    other_findings: [
      "cavity",
      "hilarlymphadenopathy",
      "bluntedcp",
      "cardiomegaly",
      "calcification",
      "nodule",
      "degenspine",
      "reticulonodularpattern",
      "scoliosis",
      "trachealshift",
      "diaphragm",
      "fracture",
      "qmsk_fracture",
      "lung_nodule_malignancy"
    ],
    tags: ["abnormal", "tuberculosis"]
  },
  qer: {
    intra_cranial_hemorrhage: [
      "IPH",
      "SDH",
      "IVH",
      "SAH",
      "EDH",
    ]
  }
}

export default FINDINGS;
