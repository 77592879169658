import configureDcmio from "../utils/configureDCMIO";
import Store from "../utils/Store";
import Toast from "./Toast";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfigureDCMIO() {
  const { t } = useTranslation();
  return (
    <p className="text-[14px]">
      {t("enterprise_users")},{" "}
      <span
        className="text-teal-400 hover:text-teal-500 dark:text-teal-200 dark:hover:text-teal-300 cursor-pointer"
        onClick={async () => {
          try {
            await configureDcmio();
            Toast.success(t("toast.configured_successfully"));
            Store.update((s) => {
              s.isConnectModalityOpen = false;
            });
          } catch (error) {
            Toast.error(t("error_key"));
          }
        }}
      >
        {t("configure_dcmio")}
      </span>
    </p>
  );
}
