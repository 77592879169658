import React from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import Toast from "../Toast";
import useAddTemplate from "./services/useAddTemplate";
import { useTranslation } from "react-i18next";
import useAuthService from "../../hooks/useAuthService";
import { get } from "lodash";
import useEditTemplate from "./services/useEditTemplate";
import constants from "../../utils/constants";

function PreviewTemplateModal({
  currentReport,
  isVisible,
  onClose,
  updatedDetails,
  isPinned,
  refetchReports,
}) {
  const { t } = useTranslation();
  const addTemplate = useAddTemplate();
  const editTemplate = useEditTemplate();
  const onCloseModal = () => {
    onClose();
  };

  const { data: authData } = useAuthService(true);
  const default_filter = get(authData, ["settings", "saved_filters"], []);
  const cxr_saved_filter_data = default_filter?.filter(
    (filter) =>
      filter.name === constants.DEFAULT_FILTER_NAME.negativeCXR && filter.is_default
  )[0];

  const onSave = async () => {
    try {
      const { name, modality, template, id } = updatedDetails;
      let payload = {
        name,
        modality,
        template,
        is_pinned: isPinned,
      };
      if (isPinned) {
        payload = {
          ...payload,
          filter_data: cxr_saved_filter_data?.filter,
          filter_name: cxr_saved_filter_data?.name,
        };
      }
      if (id) {
        editTemplate
          .mutateAsync({ payload, id })
          .then(() => {
            refetchReports();
            Toast.success(t("report_templates.toast.update_success"));
          })
          .catch((err) => {
            Toast.error(t("report_templates.toast.update_failed"));
          });
      } else {
        addTemplate
          .mutateAsync(payload)
          .then(() => {
            refetchReports();
            Toast.success(t("report_templates.toast.created_success"));
          })
          .catch((err) => {
            Toast.error(t("report_templates.toast.update_failed"));
          });
      }
      onClose(true);
    } catch (err) {
      onClose(true);
      Toast.error(t("report_templates.toast.update_failed"));
    }
  };
  return (
    <Modal
      show={isVisible}
      onClose={onCloseModal}
      title={t("setting_report")}
      className="w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12"
      // closeOnClickAway
    >
      <div className="flex flex-col mt-2">
        <div className="px-4 bg-white m-6">
          <div className="w-full text-gray-800 text-left h-[345px] overflow-scroll rounded-lg my-4">
            {/* <TextReport reportRef={reportRef} /> */}
            {currentReport && (
              <div
                className="lexical-preview"
                dangerouslySetInnerHTML={{
                  __html: currentReport,
                }}
              />
            )}
          </div>
        </div>
        <div className="border-t px-4 space-x-4 border-gray-700 py-4 flex items-center justify-end">
          <Button
            isSecondary
            className="grow-1 flex space-x-2 px-6 py-4 ml-4"
            onClick={() => {
              onClose();
            }}
            isPrimary={false}
          >
            {t("report_templates.back_editing_button")}
          </Button>
          <Button
            isSecondary
            className="grow-1 flex space-x-2 px-6 py-4 ml-4"
            onClick={onSave}
            isPrimary={true}
          >
            {t("button.save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PreviewTemplateModal;
