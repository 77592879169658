import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";

const TagSearch = ({
  studyName,
  isSearchClicked,
  setIsSearchClicked,
  categories,
  labelMappings,
  userSettingsConf,
  setUserSettingsConf,
  tagNo,
}) => {
  const [value, setValue] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="flex flex-col h-[1px] grow">
      <div
        className={classNames(
          "border border-gray-600 rounded-md py-2 px-3 flex items-center text-[12px] bg-gray-100 dark:bg-gray-800 z-[20]"
        )}
      >
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 sm:h-6 sm:w-6"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z"
            />
          </svg>
        </div>
        <div
          className={classNames("leading-0 flex items-center ml-2", {
            hidden: !!value,
          })}
        >
          Search |
        </div>
        <div className="w-1/2 flex items-center">
          <input
            ref={inputRef}
            type="text"
            className="border-0 bg-transparent py-0"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div
          className="ml-auto cursor-pointer text-black"
          onClick={() => setIsSearchClicked(!isSearchClicked)}
        >
          <XMarkIcon className="h-4 w-4 sm:h-6 sm:w-6 text-gray-900 dark:text-gray-100" />
        </div>
      </div>

      <div
        className={classNames(
          "mt-2 grow shrink scrollbar-light dark:scrollbar overflow-y-auto rounded-md"
        )}
      >
        {Object.keys(categories).map((category) => {
          const subCategories = categories[category];

          return (
            <div key={category} className="flex flex-col">
              {Object.keys(subCategories).map((subCategory) => {
                const tags = subCategories[subCategory];

                const filteredTags = tags.filter((tag_) => {
                  let tag;
                  if (typeof tag_ === "object") {
                    tag = tag_.choice;
                  } else {
                    tag = tag_;
                  }
                  return (labelMappings[tag] || tag)
                    .toLowerCase()
                    .includes(value.toLowerCase());
                });

                if (isEmpty(filteredTags)) {
                  return null;
                }
                return (
                  <div key={subCategory} className="text-[12px] mb-5 space-y-3">
                    <div className="flex items-center text-gray-600 dark:text-gray-200 sticky top-0 bg-gray-100 dark:bg-gray-800 px-3">
                      {labelMappings[category]} | {labelMappings[subCategory]}
                    </div>
                    <div className="flex flex-wrap gap-3 px-5">
                      {filteredTags.map((tag_) => {
                        let tag;
                        if (typeof tag_ === "object") {
                          tag = tag_.choice;
                        } else {
                          tag = tag_;
                        }
                        return (
                          <div
                            key={tag}
                            className={classNames(
                              "bg-gray-200 dark:bg-gray-700 rounded-full px-4 py-2 cursor-pointer hover:bg-teal-400 dark:hover:bg-teal-400 hover:text-gray-100",
                              {
                                "bg-teal-400 dark:bg-teal-400 !text-gray-100":
                                  typeof tag_ === "object"
                                    ? tag_.id ===
                                      userSettingsConf.worklist.tags[studyName][tagNo]
                                        .value
                                    : tag ===
                                      userSettingsConf.worklist.tags[studyName][tagNo]
                                        .value,
                              }
                            )}
                            onClick={() => {
                              const prevValue = get(
                                userSettingsConf,
                                ["worklist", "tags", studyName, tagNo, "value"],
                                null
                              );
                              const selectedValue =
                                typeof tag_ === "object" ? tag_.id : tag_;

                              setUserSettingsConf({
                                ...userSettingsConf,
                                worklist: {
                                  ...userSettingsConf.worklist,
                                  tags: {
                                    ...userSettingsConf.worklist.tags,
                                    [studyName]: {
                                      ...userSettingsConf.worklist.tags[studyName],
                                      [tagNo]: {
                                        category:
                                          selectedValue === prevValue ? null : category,
                                        value:
                                          selectedValue === prevValue
                                            ? null
                                            : selectedValue,
                                      },
                                    },
                                  },
                                },
                              });
                              setIsSearchClicked(false);
                            }}
                          >
                            {labelMappings[tag] || tag}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TagSearch;
