import { PlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Store from "../../utils/Store";
import CreateUserModal from "./CreateUserModal";
import useGetUserGroups from "./services/useGetUserGroups";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

function UserGroupManagement({ isOpen }) {
  const { t } = useTranslation();
  const [showAddEditUserGroup, toggleAddEditUserGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const { data: userGroupdData, refetch: refetchUserGroups } = useGetUserGroups(isOpen);
  const groups = get(userGroupdData, ["groups"], []);

  const onCloseAddEditModal = (updated) => {
    if (updated) {
      refetchUserGroups();
    }
    toggleAddEditUserGroup(false);
    setSelectedGroup({});
  };

  return (
    <>
      <div className="mb-5 sm:my-5 space-y-6 flex flex-col text-left h-full p-4">
        <div className="space-y-2">
          <p className="text-base">{t("user_group.creating")} </p>
          <p className="text-gray-800 dark:text-gray-300 text-md">
            {t("user_group.text")}
          </p>
        </div>
        <div className="flex flex-col flex-1 justify-between sm:space-y-6">
          <div
            className={classNames(
              "max-h-96 sm:max-h-60 mb-6 sm:mb-0 overflow-y-auto scrollbar-light dark:scrollbar space-y-3",
              {
                hidden: isEmpty(groups),
              }
            )}
          >
            {groups?.map((group, index) => {
              const usersList = get(group, ["users"], []);
              let membersList = usersList
                .slice(0, 4)
                .map((m) => m.email)
                .join(", ");
              let moreCount = "";
              if (usersList.length > 4) {
                moreCount = `+${usersList.length - 4}`;
              }
              return (
                <div
                  key={index}
                  className="bg-gray-300 dark:bg-gray-900 flex justify-between px-4 py-5 w-full rounded-lg cursor-pointer"
                  onClick={() => {
                    setSelectedGroup(group);
                    toggleAddEditUserGroup(true);
                  }}
                >
                  <div className="flex flex-col space-y-2 w-full">
                    <div className="text-gray-800 dark:text-gray-500 text-base">
                      {group?.name}
                    </div>
                    <div className="text-md text-gray-800 dark:text-gray-200 flex-wrap">
                      <span className="text-teal-600 dark:text-teal-300">
                        {t("user_group.members")}{" "}
                      </span>
                      {membersList}{" "}
                      <span className="text-teal-500 font-semibold">{moreCount}</span>
                    </div>
                  </div>
                  <PencilSquareIcon
                    className="text-gray-600 dark:text-gray-500 h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
              );
            })}
          </div>
          <div className="flex">
            <button
              onClick={() => toggleAddEditUserGroup(true)}
              className={
                "inline-flex border bg-gray-600 hover:bg-teal-800 dark:bg-transparent dark:border-teal-300 dark:hover:border-teal-800 dark:hover:bg-teal-800 w-full space-x-4 items-center justify-center text-base font-medium rounded-6 text-gray-100 py-4 px-4"
              }
            >
              <PlusIcon
                className="block text-white cursor-pointer h-6 w-6"
                aria-hidden="true"
              />
              <p>{t("user_group.new_button")}</p>
            </button>
          </div>
        </div>
      </div>
      <CreateUserModal
        selectedGroup={selectedGroup}
        isVisible={showAddEditUserGroup}
        onClose={onCloseAddEditModal}
      />
    </>
  );
}

export default UserGroupManagement;
