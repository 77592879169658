import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

const Toast = () => {
  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar
      autoClose={3000}
      bodyClassName="toast-body"
      toastClassName="rounded-md flex mb-5 last:mb-8"
      theme="light"
    />
  );
};

Toast.success = (msg) => {
  return toast.success(msg, {
    icon: <CheckCircleIcon className="text-green-600 h-7 w-7" aria-hidden="true" />,
  });
};

Toast.warning = (msg) => {
  return toast.warning(msg, {
    icon: (
      <ExclamationTriangleIcon className="text-yellow-600 h-7 w-7" aria-hidden="true" />
    ),
  });
};

Toast.error = (msg) => {
  return toast.error(msg, {
    icon: <XCircleIcon className="text-red-600 h-7 w-7" aria-hidden="true" />,
  });
};

export default Toast;
