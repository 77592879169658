const settingsConfig = {
  aiFindings: {
    title: "notification_modal.aifindings_title",
    description: "notification_modal.aifindings_description",
    value: true,
    radio: [
      {
        title: "notification_modal.aifindings_radio_title1",
        value: false,
      },
      {
        title: "notification_modal.aifindings_radio_title2",
        value: false,
        selectSpecific: false,
        selectedFindingsCount: 0,
        findings: {},
      },
    ],
  },
  scansAndReporting: {
    title: "notification_modal.scansandreporting_title",
    description: "",
    value: false,
    check: [
      {
        title: "notification_modal.scansandreporting_radio_title1",
        value: false,
      },
      {
        title: "notification_modal.scansandreporting_radio_title2",
        value: false,
      },
      {
        title: "notification_modal.scansandreporting_radio_title3",
        value: false,
      },
      {
        title: "notification_modal.scansandreporting_radio_title4",
        value: false,
      },
    ],
  },
};

export default settingsConfig;
